@charset "utf-8";

/* =========================================================
　◆BreadClumb
========================================================= */
.bl_breadClumb {
	$borderColor: #999;
	&_inner { margin: 0 auto; } 
	&_list { display: flex; }
	&_item { position: relative;
		&:not(:last-of-type) { padding-right: 15px;
			&::after { content: ""; display: block; position: absolute; top: 50%; right: 0; width: 5px; height: 5px; margin-right: 10px; border-top: solid 1px $borderColor; border-right: solid 1px $borderColor; transform: rotate(45deg) translateY(-50%); }
		}
	}
	&_link { display: block; }
	@include mediaQ(PC) { display: flex; align-items: center; min-width: $pcInner; height: 40px; background: #f0f6fa; border-bottom: solid 1px #e4eaf0;
		&_inner { width: $pcInner; }
		&_link { color: #000; font-size: 13px; }
	}
	@include mediaQ(SP) {
		&_inner { width: $spInner; margin: 0 auto; }
		&_list { overflow: auto;  white-space: nowrap; }
	}
}
