@charset "utf-8";

// clearfix
@mixin cFix { content: ""; clear: both; display: block;}

// 横幅を px → % へ変換
@mixin widthPct( $parentPxWidth, $pxWidth ){
    width: floor((( $pxWidth / $parentPxWidth ) * 10000% )) / 100;
}

@mixin fz($fz) {
	$rem: $fz / 10;
	font-size: #{$fz}px;
	font-size: #{$rem}rem;
}


// メディアクエリ
$breakpoints: (
	'PC': 'screen and (min-width: 768px)',
	'SP': 'screen and (max-width: 767px)',
) !default;
// @mixinの定義
@mixin mediaQ($breakpoint: SP) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// * hack IE
$hack-ie11: "*::-ms-backdrop,";
@mixin hack-ie($hack-ver:'') {
	// * IE10 hack(add propary \9)
	@if $hack-ver == "ie10" {
		@media all and (-ms-high-contrast:none\0) {
			@content;
		}
	}
		// * IE11 hack(add selector *::-ms-backdrop)
	@else if $hack-ver == "ie11" {
		@media all and (-ms-high-contrast:none) {
			@content;
		}
	}
}
