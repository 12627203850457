@charset "utf-8";

.un_favorite {
	.un_pageTitle { letter-spacing: 0.06em; font-weight: 500; }
	.un_contactArea { background: #fff; border: solid 1px #e8eef2;
		&_txt { text-align: center; letter-spacing: 0.06em; }
		&_btn { margin: 0 auto;
			&_link { display: flex; align-items: center; justify-content: center; width: 100%; color: #fff; background: #3cb446; border-right: solid 1px #5ad264; border-bottom: solid 1px #5ad264; border-radius: 4px; box-shadow: 1px 1px 0 #1e9628; }
		}
	}
	@include mediaQ(PC) {
		.ly_main_inner { padding: 50px 0 60px; }
		.un_pageTitle { font-size: 38px; margin-bottom: 10px; }
		.un_contactArea { padding: 34px 0 40px;
			$contactArea: ".un_contactArea";
			&#{$contactArea}__top { margin: 13px 0 40px; }
			&#{$contactArea}__bottom { margin-top: 50px; }
			&_txt { font-size: 16px; margin-bottom: 29px; }
			&_btn { width: 225px;
				&_link { height: 58px; font-size: 16px; letter-spacing: 0.02em;;
					img { width: 22px; margin-right: 9px; }
				}
			}
		}
	}

	@include mediaQ(SP) {
		.bl_breadClumb { display: none; }
		.ly_main_inner { padding: 25px 0 50px; }
		.un_pageTitle { font-size: 21px; }
		.un_contactArea { padding: 20px 0 13px;
			$contactArea: ".un_contactArea";
			&#{$contactArea}__top { margin: 7px 0 20px; }
			&#{$contactArea}__bottom { margin-top: 15px; }
			&_txt { font-size: 15px; margin-bottom: 15px; }
			&_btn { width: 94.202%; max-width: 325px;
				&_link { height: 48px; font-size: 20px; letter-spacing: 0.08em;
					img { width: 25px; margin-right: 16px; }
				}
			}
		}
	}
}

