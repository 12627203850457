@charset "utf-8";

.un_privacy {
	&_contents { background: #fff; border: solid 1px #e8eef2; border-radius: 4px;
		&_overview { letter-spacing: 0.06em; }
		&_txt { letter-spacing: 0.06em; }
		&_dataList { letter-spacing: 0.06em; }
		&_management {
			&_txt { letter-spacing: 0.06em; }
			&_info { letter-spacing: 0.06em;
				&_title { font-weight: bold; }
			}
		}
	}
	@include mediaQ(PC) {
		.ly_main_inner { padding: 60px 0 110px; }
		&_contents { padding: 30px 40px 25px;
			$contents: &;
			&_overview { font-size: 15px; line-height: 2; margin-bottom: 38px; }
			.bl_sect { margin-bottom: 38px;
				&_title { font-size: 24px; margin-bottom: 12px; }
				#{$contents}_txt { font-size: 15px; line-height: 2; }
				#{$contents}_dataList { font-size: 15px; line-height: 2; margin-top: 30px; }
			}
			&_management { margin-top: 65px;
				&_txt { font-size: 15px; line-height: 2; margin-bottom: 32px; }
				&_info {
					&_title { font-size: 16px; line-height: 2.25; margin-bottom: 5px;
						&_office { margin-left: 1em; }
					}
					&_data { font-size: 15px; line-height: 2; }
				}
			}
		}
	}

	@include mediaQ(SP) {
		.bl_breadClumb { display: none; }
		.bl_mv {
			&_pageTitle { padding-top: 65px; }
		}
		.ly_main_inner { padding-bottom: 50px; }
		&_contents { padding: 15px 20px;
			$contents: &;
			&_overview { font-size: 13px; line-height: 1.62; margin-bottom: 20px; }
			.bl_sect { margin-bottom: 20px;
				&_title { font-size: 18px; padding-bottom: 15px; margin-bottom: 10px; }
				#{$contents}_txt { font-size: 13px; line-height: 1.62; }
				#{$contents}_dataList { font-size: 13px; line-height: 1.62; margin-top: 21px;
					&_data { text-indent: -1em; margin-left: 1em; }
				}
			}
			&_management {
				&_txt { font-size: 13px; line-height: 1.62; margin-bottom: 25px; }
				&_info {
					&_title { font-size: 14px; line-height: 1.5;
						&_office { display: block; font-size: 13px; line-height: 1.84; font-weight: 400; }
					}
					&_data { font-size: 13px; line-height: 1.62; }
				}
			}
		}
	}
}

