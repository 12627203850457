@charset "utf-8";

/* =========================================================
　◆Head
========================================================= */
.ly_head { width: 100%; background: #fff;
	$head: &;
	$headInner: '.ly_head_inner';
	&_inner { margin: 0 auto; }
	&_option { color: #fff; background: #3cb446; }
	&_main {
		#{$headInner} { display: flex; align-items: center; justify-content: space-between; }
		&_logo {
			&_link { display: flex; align-items: center; }
		}
		&_nav {
			$nav: &;
			&_item {
				&__favorite {
					& #{$nav}_link { display: flex; align-items: center; justify-content: center; color: #fff; font-size: 16px; background: #ff6e00; border-radius: 3px; border: solid 1px #ff9614; }
				}
			}
		}
	}
	@include mediaQ(PC) {
		& { min-width: $pcMinInner;
			&_inner { width: $pcInner; }
			&_option { letter-spacing: 0.06em; padding: 13px 0;
				#{$headInner} { display: flex; align-items: center; justify-content: space-between; }
				&_txt { font-size: 13px; }
				&_list { display: flex; align-items: center; }
				&_item:not(:last-of-type) { margin-right: 32px; }
				&_link { display: flex; align-items: center; color: #fff; font-size: 15px;
					img { margin-right: 6px; }
				}
			}
			&_main { padding: 10px 0;
				&_logo {
					&_mark { margin-right: 10px; }
				}
				&_nav {
					$nav: &;
					&_list { display: flex; align-items: center; }
					&_item{ margin-right: 28px;
						&__search { margin-right: 15px; }
						&:last-of-type { margin-right: 0; }
						&__favorite {
							& #{$nav}_link { width: 210px; height: 60px;  box-shadow:2px 2px 0px 2px #f05000; }
						}
					}
					&_link { display: flex; align-items: center; color: #010000; font-size: 20px; letter-spacing: 0.06em;
						img { margin-right: 6px; }
					}
				}
				&_search { overflow: hidden; display: flex; align-items: center; width: 240px; height: 40px; border-radius: 20px; background: #f0f6fa;
					&_input { width: 200px; height: 100%; font-size: 13px; padding: 0 6px 0 20px; background: none; border: none; outline: none; }
					&_submit { width: 32px; height: 32px; background: #8296aa; border-radius: 50%; border: none; outline: none;
						img { margin: 0 auto; }
					}
				}
		
			}
		
		}
	}
	@include mediaQ(SP) {
		& { position: fixed; z-index: 3; border-bottom: 1px solid #999;
			#{$headInner} { width: 100%; }
			&_option { letter-spacing: 0.04em;
				& #{$headInner} { display: flex; align-items: center; justify-content: center; padding: 7px 0; }
				&_txt { font-size: 12px; letter-spacing: 0.04em; padding: 0 25px; }
			}
			&_main { display: flex; align-items: center; height: 60px; padding: 4px 0;
				#{$headInner} { padding: 0 0.5333% 0 4%; }
				&_logo { width: 54.7752%; max-width: 194px;
					&_mark { width: 16.923%; max-width: 33px; margin-right: 7px; }
					&_txt { width: 78.974%; max-width: 154px; }
				}
				&_nav { display: flex; align-items: flex-start; justify-content: flex-end; width: 40.449%;
					$nav: &;
					&_list { width: 54.861%; max-width: 80px; margin-right: 5px; }
					&_item {
						&__favorite { 
							& #{$nav}_link { flex-direction: column; height: 50px; font-size: 12px;  box-shadow:1px 1px 0px 1px #f05000;
								&_favoriteMark { width: 18px; }
							}
						}
					}
					&_menuBtn { position: relative; width: 60px; height: 52px; overflow: hidden; text-indent: 100%; white-space: nowrap; border: solid 1px #3cb446; border-radius: 3px;
						&_line {
							$lineHeight: 2px;
							$lineColor: #3cb446;
							$lineRadius: 2px;
							position: absolute; top: 50%; left: 50%; width: 50%; height: $lineHeight; text-indent: 200%; white-space: nowrap; background: $lineColor; transform: translate(-50%, -50%);
							&::before, &::after { content: ""; display: block; position: absolute; width: 100%; height: $lineHeight; background: $lineColor; border-radius: $lineRadius; }
							&::before { top: -9px; left: 0; }
							&::after { top: 9px; left: 0; }
						}
					}
				}
			}
		}
		.bl_spMenu { position: absolute; top: 5px; right: 5px; width: 30px; height: 30px;
			$spMenu: &;
			$borderHeight: 2px;
			$borderColor: #999;
			&::before, &::after { content: ""; display: block; position: absolute; left: 0; width: 100%; height: $borderHeight; background-color: $borderColor; transition: all $animationTime; }
			&::before { top: 0; }
			&::after { bottom: 0; }
			&_txt { overflow: hidden; display: block; position: absolute; top: 50%; width: 100%; height: $borderHeight; white-space: nowrap; text-indent: 100%; background-color: $borderColor; transform: translateY(-50%); transition: opacity $animationTime; }
			@at-root body{
				&.is_menuOpen {
					#{$spMenu}::before { top: 50%; transform: rotate(45deg) translateY(-50%); }
					#{$spMenu}::after { bottom: 50%; transform: rotate(-45deg) translateY(50%); }
					#{$spMenu}_txt { opacity: 0; }
				}
			}
		}
	}
	&#{$head}__nav { display: flex; }
}

