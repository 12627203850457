@charset "utf-8";



.un_contact {
	&_greeting {
		&_title { letter-spacing: 0.06em; font-weight: 500; }
		&_txt { letter-spacing: 0.06em;}
	}
	&_statusList { display: flex; justify-content: space-between; width: 98%;
		&_item { letter-spacing: 0.06em; color: #fff; text-align: center; width: 33.2%; position: relative;  background: #c6c6c6;
			&:first-child { 
				&::before { content: none;}
			}
			&.is_current { background: #3cb446;
				&::after {  border-color: transparent transparent transparent #3cb446;}
			}
			&::before { content: ""; position: absolute; width: 0; height: 0; top: 0; left: 0; border-width: 30px 0px 30px 20px; border-color: transparent transparent transparent #fff; border-style: solid; z-index: 0;}
			&::after { content: ""; position: absolute; width: 0; height: 0; top: 0; right: -20px; border-width: 30px 0px 30px 20px; border-color: transparent transparent transparent #c6c6c6; border-style: solid; z-index: 1;}
		}
	}
	&_form {
		&_unit { width: 100%;}
		&_note { font-size: 12px; letter-spacing: 0.06em; color: #ff6e6e; margin-bottom: 8px;}
		&_ttl { background: #8296aa; color: #fff; font-weight: normal; letter-spacing: 0.06em; 
			&_required { display: inline-block; letter-spacing: 0.06em; background-color: #ff6e6e; }
		}
		&_txtBox { font-size: 15px; letter-spacing: 0.06em; line-height: 2; background: #fff;
			&_list { display: flex; justify-content: start; flex-wrap: wrap;}
			&_item {letter-spacing: 0.06em; 
				&:last-child { margin: 0;}
				label { display: inline-flex; align-items: baseline;}
				&_data { margin-right: 8px;}
			}
		}
		&_check { margin: 0 auto; text-align: center; margin-bottom: 25px;}
		&_txt { 
			&_note {font-size: 12px; letter-spacing: 0.06em; margin-top: 6px;}
			&_error { font-size: 12px; letter-spacing: 0.06em; color: #ff6e6e;}
			&_agreement { font-size: 15px; letter-spacing: 0.06em; 
				&_control { margin-right: 10px;}
			}
			&_btn { 
				button { border-top: solid 1px #3cb446;  border-left: solid 1px #3cb446;
					&.is_check { pointer-events: none; background: #c6c6c6; border-top: solid 1px #c6c6c6; border-left: solid 1px #c6c6c6; border-bottom: solid 1px #d9e0e7; border-right: solid 1px #d9e0e7; box-shadow: 2px 2px 0 #9d9b9b;}
				}
			}
			&_btnBack button { background: #8296aa; border-left: solid 1px #8296aa; border-top: solid 1px #8296aa; border-bottom: solid 1px #bccdde; border-right: solid 1px #bccdde; box-shadow: 2px 2px 0 #596d80;}
		}
	}
	&_terms {
		&_ttl { font-weight: 500; letter-spacing: 0.06em;}
		&_txtWrap { background: #fff; border: solid 1px #dfe4e8; border-radius: 4px; }
		&_txtBox { overflow: auto;}
		&_txt { letter-spacing: 0.06em; }
	}
	&_finishBox { background: #fff; border: solid 1px #e8eef2; border-radius: 4px;
		&_ttl { font-weight: 500; text-align: center; letter-spacing: 0.06em;}
		&_txt { letter-spacing: 0.06em;}
	}
	@include mediaQ(PC) {
		.ly_main_inner { padding: 50px 0 100px; }
		&_greeting { margin-bottom: 43px;
			&__finish { margin-bottom: 20px;}
			&_title { font-size: 38px; margin-bottom: 10px; }
			&_txt { font-size: 15px; line-height: 2;}
		}
		&_statusList { margin-bottom: 50px;
			&__finish { margin-bottom: 50px;}
			&_item { font-size: 18px; padding: 17px;}
		}
		&_form {
			&_wrap { margin-bottom: 48px;}
			&_inner {width: 100%; border-collapse: separate; margin-top: 8px;
				tr {
					&:not(:last-of-type) {
						.un_contact_form_ttl { border-bottom: solid 1px #fff; }
						.un_contact_form_txtBox { border-top: solid 1px #dfe4e8; border-right: solid 1px #dfe4e8; }
					}
					&:first-of-type {
						.un_contact_form_ttl { border-radius: 4px 0 0 0; }
						.un_contact_form_txtBox { border-radius: 0 4px 0 0; }
					}
					&:last-of-type {
						.un_contact_form_ttl { border-radius: 0 0 0 4px; }
						.un_contact_form_txtBox { border-radius: 0 0 4px 0; border-top: solid 1px #dfe4e8; border-right: solid 1px #dfe4e8; border-bottom: solid 1px #dfe4e8; }
					}
				}
			}
			&_ttl { font-size: 16px; padding: 0 40px 0 20px; width: 312px; margin-right: 30px;
				&_required {float: right; font-size: 11px; margin-top: 3px; padding: 1px 7px; }
			}
			&_txtBox { padding: 22px 30px 19px; 
				&_item { font-size: 15px; margin-right: 50px;}
				&__kind { padding: 12px 30px;}
				&__textArea { line-height: 1.4;
					textarea { height: 180px; width: 827px;}
				}
			}
			&_txt { height: 36px; width: 600px;
				&__middle { width: 358px;}
				&__long { width: 827px;}
				&_btn button { transition: opacity 0.3s; font-size: 18px !important;
					&:hover { opacity: 0.8;}
				}
				&_btnBack { margin-right: 20px !important;}
			}
		}
		&_infoSP { display: none !important;}
		&_infoBox { margin-bottom: 54px;}
		&_info { width: 100%;width: 100%; border-collapse: separate;
			tr {
				.un_contact_info_ttl__img { border-radius: 4px 0 0 0; }
				.un_contact_info_ttl__rent { border-radius: 0 4px 0 0; }
				&:not(:last-of-type) {
					.un_contact_info_ttl { border-left: solid 1px #dfe4e8;}
				}
				.un_contact_info_data { border-top: solid 1px #dfe4e8; border-left: solid 1px #dfe4e8; }
				&:last-of-type { 
					.un_contact_info_data { border-bottom: solid 1px #dfe4e8; 
						&:first-of-type { border-radius: 0 0 0 4px;}
						&:last-of-type { border-radius: 0 0 4px 0;}
					}
				}
			}
			&_unit { width: 100%; background: #fff; }
			&_ttl { font-size: 16px; padding: 6px 0; background: #8296aa; color: #fff; width: 100%; font-weight: normal; text-align: center; letter-spacing: 0.06em; 
				&__img { width: 238px;}
				&__name { width: 360px;}
				&__station { width: 290px;}
				&__floor { width: 150px;}
				&__rent { width: 157px;}
			}
			&_txt { font-size: 16px; margin-bottom: 18px; letter-spacing: 0.06em;}
			&_data { font-size: 15px; line-height: 1.4; padding: 11px 20px; height: 100%; letter-spacing: 0.06em; 
				&:last-child { border-right: solid 1px #dfe4e8;}
				&_link { color: #2d3cce; text-decoration: underline !important;}
				&__img { width: 238px;}
				&__name { width: 360px;}
				&__station { width: 290px;}
				&__floor { text-align: center; width: 150px;}
				&__rent { text-align: right; padding-right: 35px; width: 157px;}
				&_propertyImg { padding-bottom: 70%; background-position: center center; background-repeat: no-repeat; background-size: cover;}
				&_propertyNum { display: block; font-size: 13px; margin: 22px 0 10px; }
			}
		}
		&_terms { margin-bottom: 38px;
			&_ttl { font-size: 28px; margin-bottom: 16px;}
			&_txtWrap { padding: 35px 20px 35px 50px;}
			&_txtBox { height: 430px; padding-right: 30px;}
			&_txt {  font-size: 14px; line-height: 2.14;}
		}
		&_finishInner { padding-bottom: 200px !important;}
		&_finishBox { padding: 43px;
			&_ttl { font-size: 28px; margin-bottom: 40px;}
			&_txt { font-size: 15px; line-height: 2; text-align: center;}
		}
	}

	@include mediaQ(SP) {
		.bl_breadClumb { display: none; }
		.bl_mv {
			&_pageTitle { padding-top: 65px; }
		}
		.ly_main_inner { padding: 5px 0 50px; }
		&_greeting { margin-bottom: 16px;
			&__finish { margin-bottom: 12px;}
			&_title { font-size: 21px; margin-bottom: 6px; }
			&_txt { font-size: 13px; line-height: 1.84;}
		}
		&_statusList {width: 97%; margin-bottom: 20px;
			&_item { width: 33%; padding: 11px 0 11px 9px;
				&::before {  top: 0; right: -11px; border-width: 20px 0 20px 10px; }
				&::after { top: 0; right: -10px; border-width: 20px 0 20px 10px; }
			}
		}
		&_form {
			&_wrap { margin-bottom: 26px;}
			th, td { display: block;}
			&_inner {width: 100%; border-collapse: separate; margin-top: 8px;
				tr {
					.un_contact_form_txtBox { border-right: solid 1px #dfe4e8; border-left: solid 1px #dfe4e8; }
					&:first-of-type {
						.un_contact_form_ttl { border-radius: 4px 4px 0 0; }
					}
					&:last-of-type {
						.un_contact_form_txtBox { border-radius: 0 0 4px 4px; border-bottom: solid 1px #dfe4e8; }
					}
				}
			}
			&_ttl { width: 100%; padding: 11px 15px;
				&_required { font-size: 10px; margin-left: 10px; padding: 1px 5px; }
			}
			&_txtBox { width: 100%; padding: 15px 15px 16px; font-size: 13px; line-height: 1.84;
				&__kind { padding: 10px 16px;}
				&_item {margin: 0 27px 10px 0; font-size: 13px;}
				textarea { height: 165px;}
			}
			&_check {margin-bottom: 17px;}
			&_txt {  width: 100%; height: 35px;
				&_note { font-size: 10px; margin-top: 12px;}
				&_error { text-indent: -1em; padding-left: 1em; font-size: 10px; margin-top: 6px; line-height: 1.4;}
				&_agreement { font-size: 13px; 
					&_control { margin-right: 10px;}
				}
				&_btn { padding-top: 0; border: none;}
				&_btnBack { margin-top: 21px;}
			}
		}
		&_infoBox { margin-bottom: 24px;}
		&_info { display: none !important;
			&_txt { font-size: 14px; letter-spacing: 0.06em; margin-bottom: 12px;}
		}
		&_infoSP {
			.un_contact_info {
				&_unit { display: flex; justify-content: space-between; padding: 15px 15px 13px; border-right: solid 1px #dfe4e8; border-left: solid 1px #dfe4e8;  border-top: solid 1px #dfe4e8;
					&:first-of-type { border-radius: 4px 4px 0 0; }
					&:last-of-type {border-radius: 0 0 4px 4px; border-bottom: solid 1px #dfe4e8; }
				}
				&_img { width: 29%;
					&_propertyImg { padding-bottom: 100%; background-position: center center; background-repeat: no-repeat; background-size: cover;}
				}
				&_txtBox { width: 68%; margin-top: -6px;}
				&_data { font-size: 10px; line-height: 1.5; letter-spacing: 0.06em;
					&_link { color: #2d3cce; text-decoration: underline !important;}
					&_propertyNum { font-size: 10px;}
					&__name { font-size: 11px; line-height: 1.8; margin-bottom: 10px;}
					&__floor {display: inline;
						&::after { content: "/"; display: inline-block; padding: 0 2px;}
					}
					&__rent { display: inline-block;}
				}
			}
		}
		&_terms { margin-bottom: 27px;
			&_ttl { font-size: 21px; text-align: center; margin-bottom: 11px;}
			&_txtWrap { padding: 17px 10px 22px 20px;}
			&_txtBox { height: 335px; padding-right: 24px;}
			&_txt {  font-size: 13px; line-height: 1.62;}
		}
		&_finishBox { padding: 20px 15px;
			&_ttl { font-size: 18px; margin-bottom: 20px;}
			&_txt { font-size: 13px; line-height: 1.61; }
		}
	}
}

.un_contact_confirm {
	@include mediaQ(PC) {
		&_btn { display: flex; justify-content: center;
			.un_contact_form_txt_btn { margin: 0;}
		}
		.un_contact {
			&_infoBox { margin-bottom: 39px;}
			&_form_txtBox {padding: 24px 30px 23px;}
		}
	}
	@include mediaQ(SP) {
		&_btn { display: flex; flex-direction: column-reverse;
			.un_contact_form_txt_btn { width: 100%;}
		}
		.un_contact {
			&_infoBox { margin-bottom: 30px;}
			&_form {
				&_ttl { padding: 10px 15px 12px;}
				&_txtBox { padding: 10px 15px 12px;}
			}
		}
	}
}

