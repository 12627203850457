@charset "utf-8";

.pg_search {
	.bl_searchNav { 
		&_list { display: flex; }
		&_item { display: flex; }
		&_link { overflow: hidden; display: flex; align-items: center; position: relative; width: 100%; color: #000; background: #fff; border: solid 1px #e8eef2; border-radius: 4px;
			&::before, &::after { content: ""; display: block; position: absolute; }
			&::before { top: 0; right: 0; width: 14px; height: 100%; background: #8296aa; }
			&::after { top: 50%; right: 4px; width: 6px; height: 8px; border-left: solid 6px #fff; border-top: solid 4px transparent; border-bottom: solid 4px transparent; transform: translateY(-50%); }
			&.is_current {background: #feede0; border: solid 1px #fbdbc3;
				&::before { background: #ff6e00; }
			}
		}
	}
	@include mediaQ(PC) {
		.bl_breadClumb { margin-bottom: 60px; }
		.bl_searchNav { margin-bottom: 50px;
			&_list { justify-content: space-between; }
			&_item { align-items: center; width: 292px; }
			&_link { justify-content: center; height: 60px; font-size: 18px; transition: all 0.3s;
				&::before { transition: background 0.3s; }
				&:hover {background: #feede0; border: solid 1px #fbdbc3;
					&::before { background: #ff6e00; }
				}
			}
		}
	}

	@include mediaQ(SP) {
		.bl_breadClumb { display: none; }
		.bl_searchNav { margin-bottom: 32px;
			&_list { flex-direction: column; }
			&_item { width: 100%;
				&:not(:last-of-type) { margin-bottom: 5px; }
			}
			&_link { padding: 15px 20px; height: 45px; font-size: 16px; letter-spacing: 0.06em; }
		}
	}
}

