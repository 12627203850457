@charset "utf-8";

.un_company {
	&_greeting {
		&_title { letter-spacing: 0.06em; font-weight: 500; }
		&_cont { background: #fff; border: solid 1px #e8eef2; border-radius: 4px;
			&_txt { letter-spacing: 0.06em;
				$greetingTxt: &;
				&#{$greetingTxt}__signature { text-align: right; }
			}
		}
	}
	&_table { width: 100%; border-collapse: separate;
		$table: &;
		tr {
			#{$table}_title { color: #fff; font-weight: normal; background: #8296aa;
			}
			&:not(:last-of-type) {
				#{$table}_title { border-bottom: solid 1px #fff; }
				#{$table}_data { border-top: solid 1px #dfe4e8; border-right: solid 1px #dfe4e8; }
			}
			#{$table}_data { background: #fff; }
			&:first-of-type {
				#{$table}_title { border-radius: 4px 0 0 0; }
				#{$table}_data { border-radius: 0 4px 0 0; }
			}
			&:last-of-type {
				#{$table}_title { border-radius: 0 0 0 4px; }
				#{$table}_data { border-radius: 0 0 4px 0; border-top: solid 1px #dfe4e8; border-right: solid 1px #dfe4e8; border-bottom: solid 1px #dfe4e8; }
			}
		}
		&_link { color: #2d3cce; text-decoration: underline !important; }
	}
	@include mediaQ(PC) {
		.ly_main_inner { padding: 50px 0 100px; }
		&_greeting { margin-bottom: 52px;
			&_title { font-size: 38px; margin-bottom: 10px; }
			&_cont { padding: 33px 40px;
				&_txt { font-size: 15px; line-height: 2;
					&:not(:last-of-type) { margin-bottom: 30px;}
					$greetingTxt: &;
					&#{$greetingTxt}__signature { margin-top: -9px; }
				}
			}
		}
		&_table { letter-spacing: 0.06em;
			$table: &;
			tr {
				#{$table}_title { width: 232px; font-size: 16px; vertical-align: middle; padding: 15px 20px 17px; }
				#{$table}_data { font-size: 16px; padding: 0 30px; }
			}
		}
	}

	@include mediaQ(SP) {
		.bl_breadClumb { display: none; }
		.bl_mv {
			&_pageTitle { padding-top: 65px; }
		}
		.ly_main_inner { padding: 5px 0 50px; }
		&_greeting { margin-bottom: 24px;
			&_title { font-size: 21px; margin-bottom: 10px; }
			&_cont { padding: 15px 20px;
				&_txt { font-size: 14px; line-height: 1.72;
					&:not(:last-of-type) { margin-bottom: 24px; }
					$greetingTxt: &;
					&#{$greetingTxt}__signature { margin-top: -15px; }
				}
			}
		}
		&_table { letter-spacing: 0.06em;
			$table: &;
			tr {
				#{$table}_title { width: 130px; padding: 11px 20px 12px; vertical-align: top; }
				#{$table}_data { width: calc(100% - 130px); padding: 11px 20px 12px 22px; }
			}
		}
	}
}

