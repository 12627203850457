@charset "utf-8";

.un_customer {
	&_point {
		&_title {
			img { margin: 0 auto; }
		}
		&_contents { background: #fff; border-right: solid 1px #e8eef2; border-bottom: solid 1px #e8eef2; border-left: solid 1px #e8eef2; border-radius: 0 0 3px 3px;
			&_title { color: #fff; font-weight: 500; letter-spacing: 0.06em; background: #3cb446; border-radius: 3px 3px 0 0; }
			&_intro { letter-spacing: 0.06em; }
			&_finally { letter-spacing: 0.06em;}
		}
		&_sec {
			&_title { font-weight: 500; letter-spacing: 0.06em; }
			&_cont {
				&_txt { letter-spacing: 0.06em; }
				&_list {
					&_title { color: #3cb446; font-weight: bold; letter-spacing: 0.06em; }
				}
			}
		}
	}
	&_contact { display: flex; justify-content: center;
		&_btn { display: flex; align-items: center; justify-content: center; height: 48px; color: #fff; background: #3cb446; border-right: solid 1px #5ad264; border-bottom: solid 1px #5ad264; border-radius: 4px; }
	}
	&_meritDemerit {
		&Wrap { background: #f0f6fa; }
		&_cont { background: #fff; border: solid 3px #3cb446; border-radius: 4px;
			$cont: '.un_customer_meritDemerit_cont';
			$merit: '.un_customer_meritDemerit_cont__merit';
			$demerit: '.un_customer_meritDemerit_cont__demerit';
			&_title { display: flex; align-items: center; justify-content: center; font-weight: bold; letter-spacing: 0.06em; }
			&_detail {
				&_title { color: #3cb446; font-weight: bold; letter-spacing: 0.06em; }
				&_data { letter-spacing: 0.06em;; }
			}
			&#{$merit} {
				#{$cont}_title { color: #3cb446; }
			}
			&#{$demerit} {
				#{$cont}_title { color: #fff; background: #3cb446; }
			}
		}
	}
	@include mediaQ(PC) {
		.ly_main_inner { padding: 60px 0 83px; }
		&_point {
			&_title { margin-bottom: 29px;
				img { transform: translateX(-37px); }
			}
			&_contents {
				&_title { font-size: 20px; padding: 9px 22px 12px; }
				&_inner { padding: 22px 30px 70px; }
				&_intro { font-size: 15px; line-height: 2; margin-bottom: 22px; }
				&_finally { font-size: 16px; line-height: 1.875; padding: 0 30px; margin: 40px 0 23px;
					&_list { margin-bottom: 30px; }
				}
			}
			&_sec { position: relative; padding: 38px 27px 26px; border: solid 3px #3cb446; border-radius: 3px;
				&:not(:last-of-type) { margin-bottom: 20px; }
				$pointSec: '.un_customer_point_sec';
				&_title { display: flex; align-items: center; font-size: 24px; margin-bottom: 12px;
					img { width: 55px; margin-right: 30px; transform: translateY(-10px); }
				}
				&_cont { width: 100%;
					&_img { position: absolute; }
					&_txt { font-size: 15px; line-height: 2;
						&:not(:last-of-type) { margin-bottom: 30px; }
					}
					&_list {
						&_title { font-size: 18px; line-height: 1.66; margin-bottom: 10px; }
						&_data { font-size: 15px; line-height: 2; }
					}
				}
				&#{$pointSec}__point01 {
					#{$pointSec}_cont {
						&_img { width: 288px; right: 55px; bottom: 0; }
						&_txt { width: 720px; }
					}
				}
				&#{$pointSec}__point02 { display: flex; flex-direction: column; align-items: flex-end; padding: 42px 36px 32px 27px;
					#{$pointSec}_title,
					#{$pointSec}_cont { width: 720px; }
					#{$pointSec}_cont {
						&_img { width: 288px; bottom: -1px; left: 39px; }
					}
				}
				&#{$pointSec}__point03 { padding-bottom: 34px;
					#{$pointSec}_cont {
						&_img { width: 340px; right: 0; bottom: 76px; }
						&_txt { width: 730px; }
					}
				}
				&#{$pointSec}__point04 { display: flex; flex-direction: column; align-items: flex-end;
					#{$pointSec}_title { width: 730px; }
					#{$pointSec}_cont {
						&_img { position: static; float: left; margin-top: -7px; margin-left: 26px; }
						&_list {
							&_title { text-indent: -24px; margin-left: 38px; margin-bottom: 19px;
								$listTitle: '.un_customer_point_sec_cont_list_title';
								&#{$listTitle}__small { width: 705px; margin-bottom: 15px; margin-left: 388px; }
							}
							&_data { letter-spacing: 0.06em; margin-left: 14px;
								&:not(:last-of-type) { margin-bottom: 35px; }
								$listData: '.un_customer_point_sec_cont_list_data';
								&#{$listData}__small { width: 705px; margin-left: 364px; }
								&_list { margin-bottom: 14px; }
								&_txt { text-indent: 16px; }
							}
						}
					}
				}
			}
		}
		&_contact {
			&_btn { width: 242px; font-size: 20px; letter-spacing: 0.08em;
				img { width: 22px; margin-right: 14px; }
			}
		}
		&_meritDemerit { width: $pcInner; padding: 77px 0 100px; margin: 0 auto;
			&_title { width: 378px; margin: 0 auto 28px;
				img { transform: translateX(15px); }
			}
			&_contents { display: flex; justify-content: space-between; }
			&_cont { width: 580px;
				&_title { font-size: 24px; padding: 9px 0; border-bottom: solid 3px #3cb446; }
				&_detail { padding: 23px 28px 27px;
					&_title { font-size: 20px; line-height: 1.5; margin-bottom: 10px; }
					&_data { font-size: 15px; line-height: 2;
						&:not(:last-of-type) { margin-bottom: 40px; }
					}
				}
			}
		}
	}

	@include mediaQ(SP) {
		.bl_breadClumb { display: none; }
		.bl_mv {
			&_pageTitle { padding-top: 45px; }
		}
		.ly_main {
			&_inner { padding-bottom: 50px; }
		}
		&_point {
			&_title { margin-bottom: 15px;
				img { width: 91.014%; max-width: 314px; }
			}
			&_contents {
				&_inner { padding: 10px 20px 0; }
				&_title { font-size: 18px; padding: 9px 15px; }
				&_intro { font-size: 13px; line-height: 1.62; padding-bottom: 16px; }
				&_finally { padding-top: 17px; border-top: solid 1px #3cb446;
					&_txt { line-height: 1.62; }
					&_list { line-height: 1.62; margin-bottom: 20px;
						&_item { text-indent: -1em; margin-left: 1em; }
					}
				}
			}
			&_sec { padding-top: 21px; border-top: solid 1px #3cb446;
				$pointSec: '.un_customer_point_sec';
				&_title { display: flex; align-items: center; font-size: 16px;
					img { width: 39px; margin-top: 9px; margin-right: 10px; transform: translateY(-10px); }
				}
				&_cont { padding-bottom: 15px;
					&_img { float: right;
						&::after { content: ""; display: block; clear: both; }
					}
					&_txt { line-height: 1.62;
						&:not(:last-of-type) { margin-bottom: 21px; }
					}
				}
				&#{$pointSec}__point01{
					#{$pointSec}_cont {
						&_img { width: 42.18%; margin-left: 7.8%; }
					}
				}
				&#{$pointSec}__point02{
					#{$pointSec}_cont {
						&_img { width: 33.33%; margin: 0 5.718% 0 11.764%; }
					}
				}
				&#{$pointSec}__point03{
					#{$pointSec}_cont {
						&_img { width: 46.732%; margin: 5.28% 0 0 3.594%; }
					}
				}
				&#{$pointSec}__point04{
					#{$pointSec}_cont {
						&_img { width: 30.392%; margin: 2.64% 9.803% 0 7.784%; }
						&_list {
							&_title { line-height: 1.62; text-indent: -17px; margin: 0 0 6px 17px; }
							&_data { line-height: 1.62;
								&:not(:last-of-type) { margin-bottom: 15px; }
								&_list { text-indent: -13px; margin-left: 13px; margin-bottom: 23px;
									&_item { letter-spacing: 0.06em; }
								}
								&_txt { text-indent: 1em; }
							}
						}
					}
				}
			}
		}
		&_contact { margin: 15px 0 10px;
			&_btn { width: 100%; font-size: 20px;
				img { width: 24px; margin-right: 15px; }
			}
		}
		&_meritDemerit { width: $spInner; padding: 30px 0 50px; margin: 0 auto;
			&_title { width: 73.333%; max-width: 253px; margin: 0 auto 10px; }
			&_cont { 
				&:not(:last-of-type) { margin-bottom: 10px; }
				&_title { font-size: 18px; padding: 7px 0; border-bottom: solid 3px #3cb446; }
				&_detail { padding: 10px 20px;
					&_title { margin-bottom: 10px; }
					&_data { line-height: 1.62;
						&:not(:last-of-type) { margin-bottom: 20px; }
					}
				}
			}
		}
	}
}

