@charset "utf-8";

/* =========================================================
　◆Common Variable
========================================================= */
$animationTime: 0.3s;
$headerHeight: 40px;

/* =========================================================
　◆Main
========================================================= */
.ly_main { background: #fafcfe;
	&_inner { margin: 0 auto; }
	@include mediaQ(PC) {
		& { min-width: $pcMinInner;
			&_inner { width: $pcInner; }
		}
	}
	@include mediaQ(SP) {
		& { padding: 90px 0 0;
			&_inner { width: $spInner; }
		}
	}
	@media screen and (max-width: 374px) {
		& { padding: 107px 0 0; }
	}
}

/* =========================================================
　◆下層メインビジュアル
========================================================= */
.bl_mv {
	&_pageTitle { font-family: 'noto sans jp'; color: #fff; font-weight: 500; letter-spacing: 0.04em; text-align: center; text-shadow:1px 0px 1px rgba(0, 0, 0, 0.1); }
	@include mediaQ(PC) { height: 240px; background: url(/common/images/bg_search_title.jpg) repeat-x center center;
		&_pageTitle {  font-size: 48px; padding-top: 62px; }
	}
	@include mediaQ(SP) { height: 205px; background: url(/common/images/bg_search_title_sp.jpg) repeat-x center center; background-size: auto 205px; margin-bottom: 20px;
		&_pageTitle { font-size: 30px; padding-top: 55px; }
	}
}

/* =========================================================
　◆下層コンテンツ共通
========================================================= */
.bl_sect {
	&_title { font-weight: 500; letter-spacing: 0.06em; border-bottom: solid 2px #dfe4e8; }
	@include mediaQ(PC) { 
		&_title { font-size: 28px; padding-bottom: 15px; margin-bottom: 20px; }
	}
	@include mediaQ(SP) {
		&_title { font-size: 21px; padding-bottom: 10px; margin-bottom: 15px; }
	}
}

/* =========================================================
　◆物件リスト
========================================================= */
.bl_buildList { display: flex; flex-wrap: wrap; justify-content: space-between;
	&_item { overflow: hidden; position: relative; background: #fff; border: solid 1px #e8eef2;
		&_favorite { position: absolute; top: 0; right: 0; z-index: 1;
			&::before, &::after { content: ""; display: block; position: absolute; top: 0; right: 0; }
			&::before { background: #8296aa; }
			&::after { background: url(/common/images/icon_favorite_white.png) no-repeat center center; }
			&.is_favorite {
				&::before { background: #ff6e00; }
				&::after { background: url(/common/images/icon_favorite_yellow.png) no-repeat center center; }
			}
		}
		&_contact { border-top: solid 1px #e8eef2;
			&_link { display: flex; align-items: center; justify-content: center; color: #fff; text-shadow:0px 1px 0px rgba(0, 0, 0, 0.15); background: #8296aa; border-radius: 3px; }
		}
	}
	&_link { display: block; position: relative; width: 100%; height: 100%; color: #000;
		&::before, &::after { content: ""; display: block; position: absolute; right: 0; bottom: 0; }
		&::before { background: #3cb446; }
		&::after { background: url(/common/images/arrow_right.png) no-repeat center center; }
	}
	&_img { background-repeat: no-repeat; background-position: center center; background-size: cover; }
	&_txt { display: flex; flex-wrap: wrap; margin: 0 auto;
		&_area { display: flex; align-items: center; justify-content: center; color: #fff;
			$area: &;
			&#{$area}__hokkaido { background: #5aaae6 }
			&#{$area}__tohoku { background: #ff6e6e }
			&#{$area}__kanto { background: #82c828 }
		}
		&_type { display: flex; align-items: center; justify-content: center; color: #3cb446; border: solid 1px #3cb446; }
		&_price { width: 100%; color: #3cb446; font-family: 'roboto'; font-weight: bold;
			&_unit { color: #000; font-family: 'noto sans jp'; margin-left: 7px; }
			&_txt { color: #000; font-family: 'noto sans jp';  font-weight: 500; letter-spacing: 0.06em; }
		}
	}

	@include mediaQ(PC) {
		& {
			&::before, &::after { content: ""; display: block; width: 292px; }
			&::before { order: 1; }
			&_item { width: 292px; border-radius: 4px; margin-bottom: 10px;
				$item: &;
				&#{$item}__contact { position: relative; padding-bottom: 48px;
					.bl_buildList_link { padding-bottom: 12px; }
				}
				&_favorite { cursor: pointer; transition: opacity 0.3s;
					&::before, &::after { width: 40px; height: 40px; }
					&::before { border-radius: 0 0 0 3px; }
					&:hover { opacity: 0.7; }
				}
				&_contact { position: absolute; bottom: 0; left: 0; width: 100%; padding: 6px 10px 8px;
					&_link { height: 34px; font-size: 13px; letter-spacing: 0.06em;
						img { width: 23px; margin-right: 11px; }
					}
				}
			}
			&_link { padding: 4px 4px 20px; 
				&::before, &::after { width: 30px; height: 30px; }
				&::before { border-radius: 3px 0 0 0; }
			}
			&_img { width: 100%; height: 200px; border-radius: 3px; margin-bottom: 14px; }
			&_txt { width: 250px;
				&_area { width: 72px; height: 34px; font-size: 15px; letter-spacing: 0.06em; border-radius: 2px; margin-right: 4px; }
				&_type { width: 132px; height: 34px; font-size: 15px; letter-spacing: 0.06em; border-radius: 2px; }
				&_title { font-size: 18px; line-height: 1.333; letter-spacing: 0.06em; margin: 8px 0 0; }
				&_price { font-size: 28px; letter-spacing: 0.06em;;
					$price: &;
					&#{$price}__consultation { font-size: 24px; margin-top: 7px; }
					&_unit { font-size: 16px; letter-spacing: 0.06em; margin-left: 7px; }
					&_txt { font-size: 16px; letter-spacing: 0.06em; margin-right: 2px; }
				}
				&_info { font-size: 13px; line-height: 1.5; letter-spacing: 0.06em; }
			}
		}
	}

	@include mediaQ(SP) {
		& {
			&_item { width: 48.985%; border-radius: 3px; margin-bottom: 7px;
				$item: &;
				&#{$item}__contact {
					.bl_buildList_link { height: calc(100% - 33px); }
				}
				&_favorite {
					&::before, &::after { width: 26px; height: 26px; }
					&::before { border-radius: 0 0 0 3px; }
					&::after { background-size: 15px 15px; }
					&.is_favorite {
						&::after { background-size: 15px 15px; }
					}
				}
				&_contact { padding: 5px;
					&_link { height: 22px; font-size: 10px; line-height: 1; letter-spacing: 0.06em;
						img { width: 16px; margin-right: 7px; }
					}
				}
			}
			&_link { padding: 2px 2px 23px; 
				&::before, &::after { width: 20px; height: 20px; }
				&::before { border-radius: 3px 0 0 0; }
				&::after { background-size: 11px 11px; }
			}
			&_img { width: 100%; height: 0; padding-bottom: 73.619%; border-radius: 2px; margin-bottom: 10px; }
			&_txt { width: 88.165%;
				&_area { width: 33.557%; max-width: 50px; height: 25px; font-size: 12px; border-radius: 3px; margin-right: 3px; }
				&_type { width: 62.073%; max-width: 91px; height: 25px; font-size: 12px; border-radius: 3px; }
				&_title { font-size: 13px; line-height: 1.384; margin: 8px 0 0; }
				&_price { font-size: 23px; letter-spacing: 0.04em;;
					$price: &;
					&#{$price}__consultation { font-size: 18px; }
					&_unit { font-size: 11px; letter-spacing: 0.06em; margin-left: 4px; }
					&_txt { font-size: 13px; letter-spacing: 0.04em; margin-right: 5px; }
				}
				&_info { font-size: 10px; line-height: 1.6; }
			}
		}
	}
}

/* =========================================================
　◆検索チェックリスト
========================================================= */
.bl_searchArea {
	&_title { letter-spacing: 0.06em; }
	&_content { overflow: hidden;
		&_title { display: flex; align-items: center; color: #fff; letter-spacing: 0.06em; background: #3cb446; }
		&_inner {  background: #fff; border-right: solid 1px #e8eef2; border-bottom: solid 1px #e8eef2; border-left: solid 1px #e8eef2; }
		&_list { display: flex; flex-wrap: wrap; }
		&_item { font-size: 15px;
			label { display: inline-flex; }
			input { margin-right: 11px; }
		}

	}
	@include mediaQ(PC) { padding-bottom: 122px;
		&_title { font-size: 38px; margin-bottom: 15px; }
		&_content { border-radius: 2px;
			&_inner { padding: 13px 50px 34px 30px; }
			&:not(:last-of-type) { margin-bottom: 30px; }
			&_title { font-size: 20px; font-weight: bold; padding: 11px 0 11px 24px; }
			&_item { width: 186px; margin-top: 14px;
				label { align-items: baseline;
					&:hover { cursor: pointer; }
				}
				input { margin-right: 11px; }
			}
		}
	}
	@include mediaQ(SP) { padding-bottom: 52px;
		&_title { font-size: 31px; margin-bottom: 10px; }
		&_content { border-radius: 4px;
			&:not(:last-of-type) { margin-bottom: 20px; }
			&_title { font-size: 18px; font-weight: 500; padding: 10px 15px; }
			&_inner { padding: 7px 15px 17px; }
			&_list { border-radius: 4px; }
			&_item { width: 50%; margin-top: 7px;
				input { margin: 5px 12px 0 0; }
			}
		}
	}
	.bl_route {
		&_title { display: flex; align-items: center; color: #fff; background: #3cb446; }
		&_station { display: flex; flex-wrap: wrap;
			&_item {
				label { display: inline-flex; align-items: baseline; }
			}
		}
		@include mediaQ(PC) { font-weight: bold; margin-top: 30px;
			&_title { font-size: 16px; padding: 5px 5px 7px; }
			&_station { 
				&_item { width: 186px; font-size: 14px; margin-top: 10px;
					label { font-weight: normal;
						input { margin: 1px 11px 0 0; }
					}
				}
			}
		}
		@include mediaQ(SP) { margin-top: 15px; font-weight: 500;
			&_title { font-size: 14px; padding: 3px 3px 4px; }
			&_station {
				&_item { width: 50%; font-size: 13px; margin-top: 10px;
					label { font-weight: normal;
						input { transform: translateY(2px); margin-right: 10px; }
					}
				}
			}
		}
	}
}

/* =========================================================
　◆物件詳細検索
========================================================= */
.bl_detailSearch { background: #f0f6fa;
	&_inner { margin: 0 auto; }
	&_title { display: flex; align-items: center; font-size: 28px; letter-spacing: 0.06em; }
	&_contents {
		&_detailList {
			&_item { overflow: hidden; align-items: center; flex-wrap: wrap; }
			&_data {
				$dlData: &;
				&_list {
					$dlList: &;
					&#{$dlList}__area { display: flex; align-items: center; margin: 15px 0 0 10px;
						#{$dlData}_item:not(:last-of-type) { margin-right: 4px; }
						#{$dlData}_link { display: flex; align-items: center; justify-content: center; color: #fff; background: #8296aa; border-radius: 4px; }
					}
				}
				&_item {
					label { display: flex; align-items: center; }
				}
				&_input {
					$input: &;
					&[type="text"] { padding: 9px 10px; background: #f0f6fa; border: solid 1px #e8eef2; border-radius: 4px; margin: 0 4px; }
					&#{$input} { width: 100%; }
				}
			}
		}
		&_btn {
			button { color: #fff; height: 48px; font-size: 20px; letter-spacing: 0.08em; background: #3cb446; border-bottom: solid 1px #5ad264; border-right: solid 1px #5ad264; box-shadow: 2px 2px 0 #1e9628; border-radius: 3px; }
		}
	}
	@include mediaQ(PC) { padding: 55px 0 70px;
		&_inner { width: $pcInner; }
		&_title { margin-bottom: 15px;
			img { margin-right: 14px; }
		}
		&_contents {
			&_detailList { display: flex; flex-wrap: wrap; justify-content: space-between; margin-bottom: 40px;
				$dl: &;
				&_item { display: flex; padding: 17px 20px 21px 20px; background: #fff; border: solid 1px #dfe4e8; border-radius: 4px;
					$dlItem: &;
					&#{$dlItem}__small { width: 392px;
						#{$dl}_data {
							&_input { width: 86px; }
						}
					}
					&#{$dlItem}__middle { width: 596px;
						&#{dl}_title { margin-right: 40px; }
					}
					&#{$dlItem}__full { width: 100%; padding: 17px 10px 21px; }
					&#{$dlItem}__industry { padding: 17px 20px 21px; }
					&:not(:last-of-type) { margin-bottom: 10px; }
				}
				&_title { color: #3cb446; font-size: 20px; font-weight: 500; letter-spacing: 0.06em; margin-right: 22px;
					$title: &;
					&#{$title}__full { display: flex; align-items: center; width: 100%; padding-left: 10px; }
					&#{$title}__industry { padding-left: 0; }
					&_sub { color: #000; font-size: 12px; letter-spacing: 0.06em; margin-left: 4px; }
				}
				&_data {
					$data: &;
					&#{$data}__full { width: 100%;
						#{$data}_input { margin-top: 10px; }
					}
					&_input{
						$input: &;
						&[type="text"] { padding: 8px 10px;
							&:first-of-type { margin-left: 0; }
						}
						&#{$input} { width: 100%; }
					}
					&_list { display: flex; align-items: center; flex-wrap: wrap; margin-left: 25px;
						$list: &;
						&#{$list}__area { margin: 10px 0 5px 10px;
							#{$data}_item { width: 74px; }
							#{$data}_link { width: 100%; height: 30px; font-size: 14px;
								&.is_current { background: #ff6e00; }
							}
						}
						&#{$list}__state { margin: 2px 0 0 15px;
							#{$data}_item { width: 186px; margin: 14px 0 0;
								label { display: inline-flex; align-items: center;
									input { margin-right: 10px; }
								}
							}
						}
						&#{$list}__check {
							#{$data}_item { width: 140px; margin: 14px 0 0 0;
								label { display: inline-flex; align-items: center; }
							}
							#{$data}_check { margin-right: 7px; }
						}
						&#{$list}__industry { margin-left: 0; }
					}
					&_item{ display: flex; align-items: center;
						&:not(:last-of-type) { margin-right: 30px; }

					}
					&_radio { margin-right: 6px; }
				}
			}
			&_btn { width: 245px; margin: 0 auto;
				button { display: block; width: 100%; 
					&:hover { cursor: pointer; }
					img { display: inline-block; width: 26px; margin-right: 10px; }
				}
			}
		}
	}
	@include mediaQ(SP) { padding: 24px 0 50px;
		&_inner { width: $spInner; }
		&_title { justify-content: center; margin-bottom: 13px;
			img { width: 28px; margin: 0 14px 0 -14px; }
		}
		&_contents { padding: 13px 0 10px; background: #fff; border: solid 1px #e8eef2; border-radius: 4px; margin: 0 auto;
			&_termsTxt { width: 100%; font-family: "noto sans jp"; color: #3cb446; font-size: 20px; font-weight: 500; padding-top: 10px; margin-bottom: 4px; }
			&_detailList { padding-bottom: 20px;
				$dl: &;
				&_item { display: flex; align-items: center; flex-wrap: wrap; padding: 0 20px; margin-top: 6px;
					$dlItem: &;
					&#{$dlItem}__small {
						#{$dl}_data { width: calc(100% - 37px);
							&_input { width: 36.117%; max-width: 91px; }
						}
					}
					&#{$dlItem}__middle { margin-top: 12px;
						#{$dl}_title { width: 100%; letter-spacing: 0.06em; margin-bottom: 5px; }
						#{$dl}_data {
							&_list { display: flex; }
							&_item { width: 115px;
								label { display: flex; align-items: center; }
							}
							&_radio { margin-right: 6px; }
						}
					}
					&#{$dlItem}__full {
						#{$dl}_data {
							&__full { width: 100%; }
							&_input {
								&__full { margin: 0; }
							}
							
						}
					}
					&#{$dlItem}__area { border-bottom: solid 1px #e8eef2;
						#{$dl}_title { display: flex; align-items: center; color: #3cb446; font-size: 20px; letter-spacing: 0.06em;
							&_sub { color: #000; font-size: 12px; margin-left: 5px; }
						}
						#{$dl}_data {
							&_list {
								&__area { flex-wrap: wrap; margin: 8px 0 0 0;
									#{$dl}_data {
										&_item { width: calc((100% - 12px) / 4); margin: 0 4px 4px 0;
											&:nth-of-type(4n) { margin-right: 0; }
										}
										&_link { padding: 6px 0;
											&.is_current { background: #ff6e00; }
										}
									}
								}
								&__state { display: flex; flex-wrap: wrap; padding-bottom: 16px; margin-top: 14px;
									#{$dl}_data {
										&_item { width: calc(100% / 2);
											&:nth-of-type(n+3) { margin-top: 7px; }
											&:nth-last-of-type(n+3) { margin-bottom: 5px; }
											label { display: inline-flex; align-items: baseline;
												input { margin-right: 5px; transform: translateY(1px); }
											}
										}
									}
								}
							}
							
						}
					}
					&#{$dlItem}__industry { width: 100%; padding-top: 10px; border-top: solid 1px #e8eef2;
						#{$dl}_title {
							&#{$dl}_title__industry { color: #3cb446; font-size: 20px; letter-spacing: 0.06em; }
						}
						#{$dl}_data {
							&_list {
								&#{$dl}_data {
									&_list {
										&__industry { display: flex; flex-wrap: wrap; }
									}
								}
							}
							&_item { width: calc(100% / 3); margin-top: 8px;
								label { display: flex; }
								#{$dl}_data {
									&_check { margin: 3px 8px 0 0; }
								}
							}
						}
					}
				}
				&_title{ font-size: 14px; font-weight: 400; margin-right: 4px; }
			}
			&_btn { padding-top: 10px; border-top: solid 1px #e8eef2;
				button { display: flex; align-items: center; justify-content: center; width: 94.202%; margin: 0 auto;
					img { width: 26px; margin: 0 10px 0 -10px; }
				}
			}
		}
	}
}

/* =========================================================
　◆ページャー
========================================================= */
.bl_pager { display: flex; align-items: center; justify-content: center;
	$pager: &;
	&_num { display: flex; align-items: center; justify-content: center; color: #000; background: #f0f6fa; border: solid 1px #e8eef2; border-radius: 4px;
		$pagerNum: &;
		&.is_current { color: #fff; background: #8296aa; border-color: #8296aa; }
		&#{$pagerNum}__prev, &#{$pagerNum}__next { position: relative; }
		&#{$pagerNum}__prev:before, &#{$pagerNum}__next:before { content: ""; display: block; position: absolute; top: 50%; width: 6px; height: 8px; border-top-color: transparent; border-bottom-color: transparent; border-style: solid; transform: translateY(-50%); }
		&#{$pagerNum}__prev:before { border-top-width: 4px; border-right-width: 6px; border-bottom-width: 4px; border-left-width: 0; }
		&#{$pagerNum}__next:before { border-top-width: 4px; border-right-width: 0; border-bottom-width: 4px; border-left-width: 6px; }
	}
	@include mediaQ(PC) {
		&#{$pager}__top { margin-bottom: 30px; }
		&#{$pager}__bottom { margin-top: 45px; }
		&_num { width: 38px; height: 38px; margin: 0 5px;
			$pagerNum: &;
			&#{$pagerNum}__prev, &#{$pagerNum}__next { width: 98px; }
			&#{$pagerNum}__prev { margin: 0 15px 0 10px;
				&::before { left: 11px; }
			}
			&#{$pagerNum}__next { margin: 0 0 0 15px;
				&::before { right: 11px; }
			}
		}
	}
	@include mediaQ(SP) {
		&#{$pager}__top { margin-bottom: 20px; }
		&#{$pager}__bottom { margin-top: 30px; }
		&_num { width: 38px; height: 38px;
			$pagerNum: &;
			&:not(:last-of-type):not(:first-of-type) { margin: 0 3px; }
			&#{$pagerNum}__prev, &#{$pagerNum}__next { width: 73px; margin: 0 12px; }
			&#{$pagerNum}__prev {
				&::before { left: 6px; }
			}
			&#{$pagerNum}__next {
				&::before { right: 6px; }
			}
			&#{$pagerNum}__continue { pointer-events: none; width: 12px; border: none; background: none; }
		}
	}
}
