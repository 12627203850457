@charset "utf-8";

.un_index {
	.ly_main {
		&vis {
			&_inner { margin: 0 auto; }
			&_banners {
				&_link { display: block; background: #fff; border-radius: 4px;}
			}
			&_info { display: flex; justify-content: space-between;
				&_item { overflow: hidden; position: relative; background: #fff;
					&_type { display: flex; align-items: center; }
					&_icon { display: flex; align-items: center; justify-content: center; height: 100%; }
					&_txt { display: flex; align-items: center; justify-content: center; height: 100%; color: #fff; }

					&__pickup {
						$banners: ".ly_mainvis_info_item_banners";
						#{$banners}_item { margin: 0 4px; }
						#{$banners}_link { display: block; color: #000; letter-spacing: 0.06em; background: #f0f6fa; border-radius: 3px; }
						#{$banners} {
							.slick-dots { 
								li {
									button {
										&::before { content: ""; display: block; background: #8296aa; border-radius: 50%; opacity: 1; }
									}
									&.slick-active {
										button {
											&::before { background: #3cb446; }
										}
									}
								}
							}
						}
					}
					&_type { position: absolute; top: 0; left: 0; }
					&_icon { background: #3cb446; }
					&_txt { background: #82c828; }
				}
			}
		}
		@include mediaQ(PC) {
			&vis { padding: 40px 0 60px; background: url(/images/mv.jpg) no-repeat center center /cover; 
				&_inner { width: $pcInner; }
				&_banners { display: flex; justify-content: space-between; margin-bottom: 20px;
					&_item { width: 597px; }
					&_link { padding: 4px; }
				}
				&_info {
					&_item { padding: 4px; border-radius: 4px;
						$item: ".ly_mainvis_info_item";
						&__area { width: 367px;
							#{$item}_img { padding: 0 0 8px; margin-top: 12px; }
							area { outline: none; }
						}
						&__railway { width: 367px;
							#{$item}_state { display: flex; justify-content: space-between; text-align: center; padding: 50px 10px 0;
								$state: ".ly_mainvis_info_item_state";
								&_list { overflow: hidden; width: 110px; border-radius: 3px; padding-bottom: 4px; }
								&_name { color: #fff; font-size: 16px; letter-spacing: 0.06em; text-align: center; padding: 6px 0; }
								&_data { position: relative; text-align: center; background: #fff; border-radius: 2px; margin-top: 4px;
									&Wrap { padding: 0 4px; }
									&::before { content: ""; display: block; position: absolute; right: 2px; bottom: 2px; width: 8px; height: 8px; border-top: solid 8px transparent; border-bottom: solid 8px; border-left: solid 8px transparent;  }
									&_link { display: block; width: 100%; color: #000; font-size: 15px; letter-spacing: 0.06em;  padding: 7px 0 6px; }
								}
								&_list {
									&__hokkaido { background: #d2e8f6;
										#{$state}_name { background: #5aaae6; }
										#{$state}_data {
											&::before { border-bottom-color: #5aaae6; }
										}
									}
									&__tohoku { background: #fadce0;
										#{$state}_name { background: #ff6e6e; }
										#{$state}_data {
											&::before { border-bottom-color: #ff6e6e; }
										}
									}
									&__kanto { background: #e6f6de;
										#{$state}_name { background: #82c828; }
										#{$state}_data {
											&::before { border-bottom-color: #82c828; }
										}
									}
								}
							}
						}
						&__pickup { width: 448px; padding-top: 50px;
							$banners: ".ly_mainvis_info_item_banners";
							#{$banners}_item { margin: 0 4px; }
							#{$banners}_link { font-size: 16px; padding: 4px; outline: none; }
							#{$banners}_txt { padding: 10px 12px; line-height: 1.375; }
							#{$banners} {
								.slick-dots { bottom: -33px;
									li {
										button {
											&::before { width: 12px; height: 12px; }
										}
									}
								}
							}
						}
						&_type { height: 40px; }
						&_icon { width: 40px; }
						&_txt { width: 140px; font-size: 16px; }
					}
				}
			}
			&_inner { padding: 50px 0 0; }
		}

		@include mediaQ(SP) {
			&vis { padding: 20px 0; background: url(/images/mv_sp.jpg) no-repeat center center /cover;
				&_inner { width: 89.333%; }
				&_banners { margin-bottom: 10px;
					&_item {
						&:not(:last-of-type) { margin-bottom: 4px; }
					}
					&_link { padding: 2px;
						img { width: 100%; }
					}
				}
				&_linkList { overflow: hidden; background: #3cb446; border-bottom: solid 1px #5ad264; border-radius: 3px; box-shadow: 0 1px 0 #148c1e;
					&_item { display: flex; width: 50%; }
					&_link { display: flex; align-items: center; width: 100%; color: #fff; font-size: 16px; padding: 12px 0 13px 0;
						&__area { padding-left: 17px; border-right: solid 1px #5ad264;
							img { width: 25px; margin-right: 3px; }
						}
						&__railway { padding-left: 18px; border-left: solid 1px #148c1e;
							img {width: 21px; margin-right: 7px; }
						}
						&_inner { display: block; width: 100%; align-items: center; }
					}
				}
				&_info { flex-wrap: wrap;
					&_item {
						&__pickup { padding: 40px 2px 2px; margin-top: 10px; border-radius: 3px; }
						&_type { overflow: hidden; height: 35px; border-radius: 0 0 3px 0; }
						&_icon { width: 35px; height: 100%;
							img { width: 20px; }
						}
						&_txt { width: 105px; height: 100%; font-size: 16px; }
						&_banners {
							&_link { padding: 2px;
								img { width: 100%; }
							}
							&_txt { font-size: 12px; line-height: 1.333; padding: 5px 9px; }
							.slick-dots{ bottom: -28px;
								li { left: 6px; margin: 0 2px;
									button{
										&::before { top: 5px; width: 10px; height: 10px; }
									}
								} 
							} 
						}
					}
				}
			}
			&_inner { padding: 36px 0 0; }
		}
	}
	.un_build {
		&_title { display: flex; align-items: center; font-weight: 500; letter-spacing: 0.06em;
			&_en { color: #3cb446; }
		}
		@include mediaQ(PC) {
			& { padding-bottom: 40px;
				&:last-of-type { padding-bottom: 50px; }
			}
			&_title { font-size: 38px; margin-bottom: 10px;
				img { width: 44px; margin-right: 17px; }
				&_en { font-size: 20px; margin-left: 12px; }
			}
		}

		@include mediaQ(SP) {
			& { padding-bottom: 23px;
				&:last-of-type { padding-bottom: 34px; }
			}
			&_title { font-size: 26px; margin-bottom: 10px;
				img { width: 37px; margin-right: 14px; }
				&_en { font-size: 13px; letter-spacing: 0.12em; margin-left: 12px; }
			}
		}
	}
}

