@charset "utf-8";

/* =========================================================
　◆Gnav
========================================================= */
.ly_gnav { position: fixed; left: 100%; top: 0; z-index: 3; width: 100%; height: 100%; transition: left $animationTime; width: 100%; background: rgba(0, 0, 0, 0.3);
	$gnav: &;
	&_overlay { position: absolute; left: 0; top: 0; z-index: 4; width: 100%; height: 100%; }
	&_inner { position: absolute; top: 0; right: 0; z-index: 5; width: 68%; min-height: 100%; padding-top: 60px; background: #fcfdff; }
	@at-root body {
		&.is_menuOpen {
			#{$gnav} { overflow: auto; left: 0; }
		}
	}
	&_close { overflow: hidden; position: absolute; top: 16px; right: 16px; width: 30px; height: 30px; text-indent: 100%; white-space: nowrap; transform: rotate(45deg);
		&_btn {
			$btnWidth: 30px;
			$btnHeight: 2px;
			$btnColor: #36be5a;
			display: block; position: absolute; top: 50%; width: $btnWidth; height: $btnHeight; background: $btnColor; transform: translateY(-50%);
			&::before { content: ""; display: block; position: absolute; top: 0; left: 50%; width: $btnHeight; height: $btnWidth; background: $btnColor; transform: translate(-50%, -50%); }
		}
	}
	&_option { width: 76.4705%; margin: 0 auto 20px;
		&_search { overflow: hidden; display: flex; position: relative; width: 100%; height: 31px; background: #f3f8fb; border: solid 1px #e9eef2; border-radius: 15px; margin-bottom: 20px;
			&_input { width: 100%; padding: 0 34px 0 14px; border: none; outline: none; }
			&_submit { position: absolute; top: 2px; right: 2px; width: 25px; height: 25px; padding: 0; background: #8296aa; border: none; outline: none; border-radius: 50%;
				img { width: 12px; margin: 0 auto; }
			}
		}
		&_link { display: flex; align-items: center; width: 100%; height: 36px; color: #fff; font-size: 15px; background: #ff8516; border: solid 1px #ffa82a; box-shadow:0 0 0 1px #f05000; border-radius: 5px;
			&_favoriteMark { width: 11.282%; max-width: 22px; margin: 0 2.0512% 0 2.564%; }
		}
	}
	&_list { width: 84.313%; border-top: solid 1px #e9eef2; margin: 0 auto; }
	&_link { display: flex; align-items: center; color: #000; font-size: 15px; padding: 10px 0 10px 11px; border-bottom: solid 1px #e9eef2;
		$link: &;
		&#{$link}__useIcon { height: 67px; }
		&#{$link}__area {
			img { width: 25px; margin: 0 15px 0 -1px; }
		}
		&#{$link}__railway {
			img { width: 22px; margin: 0 17px 0 1px; }
		}
	}
}
#spMenu { display: block; top: 0; right: 0; position: absolute; z-index: 3; cursor: pointer; width: 40px; height: 40px; }


