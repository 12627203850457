@charset "utf-8";

/* =========================================================
　◆Common Variable
========================================================= */
$animationTime: 0.3s;
$headerHeight: 40px;
$pcInner: 1200px;
$pcMinInner: 1260px;
$spInner: 92%;

$hokkaidoBg: #5aaae6;
$tohokuBg: #ff6e6e;
$kantoBg: #82c828;