@charset "utf-8";

.un_adCustomer {
	&_title { color: #fff; font-weight: 500; letter-spacing: 0.06em; background: #3cb446; border-radius: 4px 4px 0 0; }
	&_sect { background: #fff; border: solid 1px #e8eef2; border-radius: 0 0 4px 4px;
		$sect: &;
		&_contents {
			&_txt { letter-spacing: 0.06em; }
		}
		&#{$sect}__price {
			#{$sect}_contents {
				&_cont {
					&_txt { letter-spacing: 0.06em; }
				}
			}
		}
	}
	&_otherInfo { background: #f0f6fa;
		$OI: ".un_adCustomer_otherInfo";
		&_contWrap { background: #fff; border: solid 1px #dfe4e8; border-radius: 4px; }
		&_contact { margin-top: 35px;
			&_btn { display: flex; align-items: center; justify-content: center; color: #fff; background: #3cb446; border-right: solid 1px #5ad264; border-bottom: solid 1px #5ad264; border-radius: 4px; margin: 0 auto 10px; }
			&_notice { text-align: center; }
		}
		&_contents { margin: 0 auto;
			$OIContents: ".un_adCustomer_otherInfo_contents";
			&_title { font-weight: 500; }
			&#{$OIContents}__terms {
				#{$OI}_cont { overflow: auto; letter-spacing: 0.06em;
					&_title { font-weight: bold; }
					&_data { 
						&_unOrderList {
							&_item { text-indent: -1em; margin-left: 1em; }
						}
						&_orderList { list-style: none; }
					}
				}
			}
			&#{$OIContents}__applicant { 
				#{$OI}_example { border-radius: 4px;
					&_title { display: inline-block; color: #fff; letter-spacing: 0.06em; background: #3cb446; }
					&_txt { letter-spacing: 0.06em; }
				}
				#{$OI}_message {
					&_txt { letter-spacing: 0.06em; }
				}
			}
		}
		&_contact {
			&_btn { letter-spacing: 0.08em;  box-shadow: 2px 2px 0 #1e9628; }
		}
	}
	@include mediaQ(PC) {
		.ly_main {
			&_inner { padding: 60px 0; }
		}
		&_title { font-size: 20px; padding: 10px 21px; }
		&_sect { 
			$sect: ".un_adCustomer_sect";
			&_contents { padding: 25px 30px 40px;
				&_txt { font-size: 15px; line-height: 2; }
			}
			&#{$sect}__about { margin-bottom: 30px;
				#{$sect}_contents {
					&_txt { margin-bottom: 40px; }
					&_img {
						img { margin: 0 auto; transform: translateX(-10px); }
					}
				}
			}
			&#{$sect}__price { padding-bottom: 10px; margin-bottom: 30px;
				#{$sect}_contents { display: flex; justify-content: space-between; padding-top: 30px;
					&_cont { width: 540px; 
						img { margin-bottom: 20px; }
						&_txt { font-size: 15px; line-height: 2; }
					}
				}
			}
			&#{$sect}__rent {
				#{$sect}_contents { display: flex; justify-content: space-between; padding: 31px 0 30px;
					&_img { margin-left: 36px;
						img { width: 462px; }
					}
					&_txt { width: 643px; margin-right: 30px; transform: translateY(-8px); }
				}
			}
		}
		&_otherInfo {
			$OI: ".un_adCustomer_otherInfo";
			&_contents { width: $pcInner; padding-top: 53px;
				$OIContents: ".un_adCustomer_otherInfo_contents";
				&_title { font-size: 28px; margin-bottom: 15px; }
				&#{$OIContents}__terms {
					#{$OI}_contWrap { padding: 35px 20px 35px 50px; }
					#{$OI}_cont { height: 430px; font-size: 14px; line-height: 2.14; padding-right: 30px;
						&_title { margin-bottom: 5px; }
						&_data { margin-bottom: 36px;
							&_orderList { margin: 15px 0;
								&_item { text-indent: -39px; margin-left: 49px; }
							}
						}
					}
				}
				&#{$OIContents}__applicant { letter-spacing: 0.1em; padding: 90px 0 100px;
					#{$OI}_contWrap { padding: 28px 30px; }
					#{$OI}_example { padding-bottom: 20px; border: solid 3px #3cb446; margin-bottom: 10px;
						&_title { font-size: 18px; padding: 0 24px 3px 18px; margin-bottom: 15px; }
						&_txt { font-size: 18px; line-height: 1.66; text-align: center;
							&_inner { display: inline-block; text-align: left; }
						}
					}
					#{$OI}_message { display: flex; flex-direction: row-reverse; align-items: center; justify-content: space-between;
						&_img { width: 266px; margin-right: 76px; }
						&_txt { width: 734px; font-size: 15px; line-height: 2; }
					}
				}
			}
			&_contact {
				&_btn { width: 300px; height: 48px; font-size: 18px;
					img { width: 22px; margin-right: 10px; }
				}
				&_notice { font-size: 14px;  }
			}
		}
	}

	@include mediaQ(SP) {
		.bl_breadClumb { display: none; }
		.ly_main {
			&_inner { padding-bottom: 50px; }
		}
		.bl_mv {
			&_pageTitle { padding-top: 45px; }
		}
		&_title { font-size: 18px; padding: 9px 14px; }
		$ADCTtl: '.un_adCustomer_title';
		&_sect { 
			$sect: ".un_adCustomer_sect";
			&_contents { padding: 10px 20px 37px;
				&_txt { font-size: 13px; line-height: 1.63; }
			}
			&#{$sect}__about { margin-bottom: 10px;
				#{$sect}_contents {
					&_txt { margin-bottom: 27px; }
				}
			}
			&#{$sect}__price { margin-bottom: 10px;
				#{$ADCTtl} { margin-bottom: 5px; }
				#{$sect}_contents { padding-bottom: 15px;
					&_cont {
						&:not(:last-of-type) { margin-bottom: 25px; }
						img { margin-bottom: 13px; }
						&_txt { font-size: 13px; line-height: 1.62; letter-spacing: 0.06em; }
					}
				}
			}
			&#{$sect}__rent {
				#{$ADCTtl} { margin-bottom: 7px; }
				#{$sect}_contents { padding-bottom: 15px;
					&_img { margin-bottom: 10px; }
					&_txt { font-size: 13px; line-height: 1.62; letter-spacing: 0.06em; }
				}
			}
		}
		&_otherInfo {
			$OI: ".un_adCustomer_otherInfo";
			&_contents {width: $spInner; padding-top: 25px;
				$OIContents: ".un_adCustomer_otherInfo_contents";
				&_title { font-size: 21px; text-align: center; margin-bottom: 10px; }
				&#{$OIContents}__terms {
					#{$OI}_contWrap { padding: 20px 10px 20px 20px; }
					#{$OI}_cont { height: 335px; font-size: 13px; line-height: 1.61; padding-right: 25px;
						&_title { margin-bottom: 5px; }
						&_data { margin-bottom: 18px;
							&_orderList { margin: 5px 0;
								&_item { text-indent: -35px; margin-left: 40px;
									&:nth-of-type(n+10) { text-indent: -43px; margin-left: 48px; }
								}
							}
						}
					}
				}
				&#{$OIContents}__applicant { padding: 42px 0 50px;
					#{$OIContents}_title { font-size: 21px; letter-spacing: 0.06em; padding: 0 15px; margin-bottom: 13px; }
					#{$OI}_contWrap { padding: 20px 20px 16px; }
					#{$OI}_example { border: solid 2px #3cb446; margin-bottom: 15px;
						&_title { font-size: 14px; padding: 0 8px 2px 12px; margin-bottom: 7px; }
						&_txt { font-size: 14px; line-height: 1.5; padding: 0 15px 12px; }
					}
					#{$OI}_message {
						&_img { width: 72.131%; margin: 0 auto 15px; }
						&_txt { font-size: 13px; line-height: 1.62; }
					}
				}
			}
			&_contact { margin-top: 15px;
				&_btn { width: 94.202%; max-width: 325px; min-height: 48px; font-size: 20px; padding: 0 5px;
					img { width: 24px; margin-right: 13px; }
				}
				&_notice { font-size: 14px; font-weight: bold; }
			}
		}
	}
}

