@charset "utf-8";

.un_searchResult {
	.un_side {
		&_title { overflow: hidden; display: flex; align-items: center; width: 100%; color: #fff; background: #82c828; border-radius: 4px 4px 0 0;
			&_mark { display: flex; align-items: center; justify-content: center; background: #3cb446; }
			&_txt { display: flex; align-items: center; justify-content: center; font-weight: 500; }
		}
		&_contents { background: #fff; border-right: solid 1px #e8eef2; border-bottom: solid 1px #e8eef2; border-left: solid 1px #e8eef2; border-radius: 0 0 4px 4px; }
		&_cont {
			$cont: ".un_side_cont";
			$data: ".un_side_cont_data";
			&:not(:last-of-type) { border-bottom: solid 1px #e8eef2;  }
			&_ttl { display: flex; align-items: center; color: #3cb446; font-size: 20px; letter-spacing: 0.06em;
				&_sub { color: #000; }
			}
			&#{$cont}__area {
				#{$cont}_data { display: flex; flex-wrap: wrap;
					&_item { width: 72px; margin: 0 4px 4px 0; }
					&_link { display: flex; align-items: center; justify-content: center; width: 100%; color: #fff; background: #8296aa; border-radius: 4px;
						&.is_current { background: #ff6e00; }
					}
					&_txt { cursor: pointer; display: flex; align-items: center; justify-content: center; height: 30px; color: #fff; font-size: 14px; background: #8296aa; border-radius: 4px; }
				}
				#{$cont}_state { display: flex; justify-content: space-between; flex-wrap: wrap; margin-top: 20px;
					&_item {
						&:nth-last-of-type(n+3) { margin-bottom: 5px; }
						label { display: inline-flex; align-items: baseline;
							input { margin-right: 5px; transform: translateY(1px); }
						}
					}
				}
			}
			&#{$cont}__terms {
				#{$cont}_data {
					&_div { margin-bottom: 5px; }
					&_ttl { font-size: 14px; font-weight: 500; }
					&_cont { display: flex; align-items: center;
						&_input { height: 30px; background: #f0f6fa; border: solid 1px #e8eef2; border-radius: 3px; margin: 5px;
							&:first-of-type { margin-left: 0; }
						}
						&_list { display: flex; align-items: center; flex-wrap: wrap; }
						&_item {
							label { display: inline-flex; align-items: baseline; font-size: 13px; letter-spacing: 0.06em;
								input { margin-right: 6px; transform: translateY(1px); }
							}
						}
					}
				}
			}
			&#{$cont}__industry {
				#{$cont}_data { display: flex; justify-content: space-between; flex-wrap: wrap;
					&_item { margin-bottom: 10px;
						&:nth-last-of-type(-n+2) { margin-bottom: 0; }
						label { display: inline-flex; align-items: baseline; font-size: 13px; letter-spacing: 0.06em;
							input { margin-right: 8px; transform: translateY(1px); }
						}
					}
				}
			}
		}
		&_submit {
			button { display: flex; align-items: center; justify-content: center; width: 100%; height: 48px; color: #fff; font-size: 20px; font-weight: bold; letter-spacing: 0.08em; background: #3cb446; border-right: solid 1px #5ad264; border-bottom: solid 1px #5ad264; border-radius: 3px; }
		}
	}
	&_list {
		&_title { overflow: hidden; display: flex; align-items: center; background: #fff; border-radius: 4px;
			&_cnt { display: flex; align-items: center; justify-content: center; background: #3cb446; color: #fff; }
			&_num { color: #ff0; }
			&_txt { display: flex; align-items: center; font-size: 16px; letter-spacing: 0.06em;
				&_search { font-size: 20px; }
			}
		}
	}
	@include mediaQ(PC) {
		.ly_main {
			&_inner { display: flex; flex-wrap: wrap; justify-content: space-between; padding-top: 60px; }
		}
		.un_side { width: 265px;
			&_title { width: 100%; height: 50px;
				&_mark { width: 50px; height: 100%; }
				&_txt { width: calc(100% - 50px); font-size: 18px; letter-spacing: 0.06em; }
			}
			&_cont { padding: 8px 16px 15px;
				$cont: ".un_side_cont";
				&_ttl { font-weight: 500; margin-bottom: 5px;
					&_sub { font-size: 12px; }
				}
				&#{$cont}__area {
					#{$cont}_data {
						&_item {
							&:nth-of-type(3n) { margin-right: 0; }
							&:nth-last-of-type(-n+3) { margin-bottom: 0px; }
						}
						&_link { height: 30px; font-size: 14px; }
					}
					#{$cont}_state {
						&_item { width: 50%; }
					}
				}
				&#{$cont}__terms { padding-bottom: 10px;
					#{$cont}_data {
						&_cont { font-size: 12px;
							&_input { width: 85px; }
							&_list { display: flex; justify-content: space-between; width: 100%; margin: 6px 0 5px; }
							&_item { width: 50%;
								&:nth-of-type(n+3) { margin-top: 10px; }
							}
						}
					}
				}
				&#{$cont}__industry {
					#{$cont}_data { display: flex; justify-content: space-between;
						&_item { width: 50%; }
					}
				}
			}
			&_submit { padding: 10px;
				button { box-shadow: 2px 2px 0 #1e9628;
					img { margin: 0 10px 0 -10px; }
				}
			}
	
		}
		&_list { width: 896px; padding-bottom: 95px;
			&_title { margin-bottom: 10px;
				&_cnt { width: 267px; height: 70px; font-size: 16px; letter-spacing: 0.06em;
				}
				&_num { color: #ff0; font-size: 28px; padding: 0 5px; }
				&_txt { width: calc(100% - 267px); height: 70px; padding-left: 22px; border-top: solid 1px #e8eef2; border-right: solid 1px #e8eef2; border-bottom: solid 1px #e8eef2; border-radius: 0 4px 4px 0;
					&_search { margin-right: 8px; }
				}
			}
		}
	}

	@include mediaQ(SP) {
		.bl_breadClumb { display: none; }
		.ly_main {
			&_inner { padding-bottom: 30px; }
		}
		.un_side { padding-top: 15px; margin-bottom: 30px;
			&_title { position: relative; height: 50px;
				$title: ".un_side_title";
				&_mark { height: 100%; width: 50px; height: 100%; }
				&_txt { font-size: 18px; letter-spacing: 0.06em; padding-left: 16px; }
				&_accMark { position: absolute; top: 50%; right: 7px; width: 35px; height: 35px; border: solid 1px #fff; border-radius: 50%; transform: translateY(-50%);
					&::before, &::after { content: ""; display: block; position: absolute; top: 50%; left: 50%; background: #fff; transform: translate(-50%, -50%); transition: opacity 0.3s; }
					&::before { width: 20px; height: 2px; }
					&::after { width: 2px; height: 20px; }
				}
				&.is_open {
					#{$title}_accMark {
						&::after { opacity: 0; }
					}
				}
			}
			&_cont { padding: 7px 20px 20px;
				$cont: ".un_side_cont";
				$contData: ".un_side_cont_data";
				$contTtl: ".un_side_cont_data_ttl";
				&_ttl { font-size: 20px; letter-spacing: 0.06em; margin-bottom: 8px;
					&_sub { font-size: 12px; margin-left: 5px; }
				}
				&#{$cont}__area {
					#{$contData}_item { width: calc((100% - 12px ) / 4); }
					#{$contData}_item:nth-of-type(4n) { margin-right: 0; }
					#{$contData}_link { padding: 6px 0; }
					#{$cont}_state {
						&_item { width: calc(100% / 2);
							&:nth-of-type(n+3) { margin-top: 10px; }
						}
					}
				}
				&#{$cont}__terms {
					#{$cont}_ttl { margin-bottom: 2px; }
					#{$contData}_div { display:flex; align-items: center; flex-wrap: wrap; margin-bottom: 0; }
					#{$contData}_ttl { width: 30px; margin-right: 9px;
						&#{$contTtl}__full { width: 100%; margin-bottom: 6px; }
					}
					#{$contData}_cont { width: calc(100% - 39px);
						&#{$contData}_cont__full { width: 100%; }
						&_list { width: 100%; align-items: baseline; }
						&_input {
							&__small { min-width: 73px; width: calc((100% - 30px - 13px) / 2); }
						}
						&_item { width: calc(100% / 3);
							&:nth-of-type(n+4) { margin-top: 10px; }
						}
					}
					#{$contData}_div {
						&__middle { margin-top: 7px; }
					}
				}
				&#{$cont}__industry {
					#{$contData} { justify-content: flex-start;
						&_item { width: calc(100% / 3);
							label { align-items: baseline;
								input { transform: translateY(2px); }
							}
						}
					}
				}
			}
			&_submit { padding: 10px;
				button { outline: none; border-top: none; border-left: none; }
			}
			&_close { display: flex; align-items: center; justify-content: center; position: relative; height: 50px; color: #fff; font-size: 18px; font-weight: 500; letter-spacing: 0.06em; background: #8296aa;
				&_accMark { display: block; position: absolute; top: 50%; right: 6px; width: 35px; height: 35px; border: solid 1px #fff; border-radius: 50%; transform: translateY(-50%);
					&::before { content: ""; display: block; position: absolute; top: 50%; left: 50%; width: 20px; height: 2px; background: #fff; transform: translate(-50%, -50%); }
				}
			}
		}
		&_list {
			&_title { flex-direction: column; margin-bottom: 11px;
				&_cnt, &_txt { width: 100%; }
				&_cnt { height: 50px; font-size: 16px; letter-spacing: 0.06em; }
				&_num { font-size: 28px; }
				&_txt { justify-content: center; height: 45px; border-right: solid 1px #e8eef2; border-bottom: solid 1px #e8eef2; border-left: solid 1px #e8eef2;
					&_search { margin-right: 7px; }
				}
			}
		}
		.bl_detailSearch { display: none; }
	}
}

