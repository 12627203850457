@charset "utf-8";

/* =========================================================
　◆Gnav
========================================================= */
.ly_gnav {
	&_child { display: none; }
	@include mediaQ(PC) {
		&_inner { position: relative; }
		&_child { position: absolute; top: 40px; left: 0; z-index: 1; width: 100%; background: rgba(255, 255, 255, 0.3);
			&_list { display: flex; flex-wrap: wrap; }
			&_link { display: block; width: 190px; padding: 10px; }
		}
	}
	@include mediaQ(SP) {
		&_item { position: relative; }
		&_link { width: 100%; }
		&_childAccBtn { overflow: hidden; position: absolute; top: 10px; right: 0; z-index: 1; width: 40px; white-space: nowrap; text-indent: 100%; 
			&::before, &::after { content: ""; display: block; position: absolute; top: 50%; left: 50%; background: #ccc; transform: translate(-50%, -50%); }
			&::before { width: 20px; height: 2px; }
			&::after { width: 2px; height: 20px; transition: opacity $animationTime; }
			&.is_open {
				&::after { opacity: 0; }
			}
		}
		&_child { width: 100%; }
	}
}

