@charset "utf-8";

.un_searchDetail {
	$root: &;
	&_title { display: flex; letter-spacing: 0.06em; border-bottom: solid 1px #dfe4e8;
		&_area { display: inline-flex; align-items: center; justify-content: center; color: #fff; border-radius: 4px;
			$titleArea: '.un_searchDetail_title_area';
			&#{$titleArea}__hokkaido { background: $hokkaidoBg; }
			&#{$titleArea}__tohoku { background: $tohokuBg; }
			&#{$titleArea}__kanto { background: $kantoBg; }
		}
	}
	&_intro { background: #fff; border: solid 1px #e8eef2; border-radius: 4px;
		&_photo {
			&_main { background-size: cover; }
			&_thumbList { display: flex; justify-content: space-between;
				&_item {  background-position: center center; background-repeat: no-repeat; background-size: cover; }
				.slick-slide { margin: 0 2px;}
				.slick-arrow { 
					&:hover, &:focus { background:  #8296aa; }
					&.slick-prev, &.slick-next { z-index: 1000;
						&::before { content: ""; display: block; opacity: 1; border-right: 5px solid transparent; border-bottom: 6px solid #fff; border-left: 5px solid transparent; width: 5px;}
					}
					&.slick-prev { border-radius: 4px 0 0 4px; left: 0; background-color: #8296aa; height: 100%; width: 20px;
						&::before {  transform: rotate(-90deg); margin-left: 5px;}
					}
					&.slick-next { border-radius: 0 4px 4px 0; right: 0; background-color: #8296aa; height: 100%; width: 20px;
						&::before {  transform: rotate(90deg); margin-left: 3px;}
					}
				}
			}
			&_caption { margin-bottom: 10px; font-size: 13px; letter-spacing: 0.06em; line-height: 1.5;}
		}
		&_table { width: 100%;
			&_title, &_data { letter-spacing: 0.06em; border-bottom: solid 1px #dfe4e8; }
			&_title { font-weight: 500; }
			&_data { font-weight: 400; }
		}
		&_rent { color: #ff6e00; font-weight: 700; letter-spacing: 0.06em; }
		&_cost { overflow: hidden; border-radius: 4px;
			&_unit {
				&_title { color: #fff; letter-spacing: 0.02em; background: #8296aa; }
			}
		}
		&_description { border-top: solid 1px #dfe4e8;
			&_txt { letter-spacing: 0.06em; }
		}
	}
	&_sectTitle { font-weight: 500; letter-spacing: 0.06em; border-bottom: solid 1px #dfe4e8; }
	&_contents {
		#{$root}_overview {
			#{$root}_sect { 
				$sect: ".un_searchDetail_sect";
				&_contTitle { letter-spacing: 0.06em; font-weight: 500; }
				&_dataList {
					&_group { display: flex; background: #fff; }
					&_title { color: #fff; letter-spacing: 0.06em; background: #8296aa; }
				}
			}
			&_tel { background: #fff; border: solid 1px #e8eef2; border-radius: 4px;
				&_txt { font-weight: 500; text-align: center; border-bottom: solid 1px #e8eef2; }
				&_num { display: flex; align-items: center; justify-content: center; font-weight: 500; }
			}
		}
	}
	&_btns { display: flex; flex-wrap: wrap;
		$btns: &;
		&_link { display: flex; align-items: center; justify-content: center; color: #fff; border-radius: 4px;
			$btnsLink: &;
			&#{$btnsLink}__favorite { background: #ff6e00; border-right: solid 1px #ff9614; border-bottom: solid 1px #ff9614; box-shadow: 2px 2px 0 #f05000; }
			&#{$btnsLink}__mail { background: #3cb446; border-right: solid 1px #5ad264; border-bottom: solid 1px #5ad264; box-shadow: 2px 2px 0 #1e9628; }
		}
		&#{$btns}__bottom { justify-content: center; }
	}
	&_banners {
		&_link { display: block; padding: 5px; background: #fff; border: solid 1px #e8eef2; border-radius: 4px; }
	}
	&_related { width: 100%;
		.slick-arrow { background: #3cb446;

		}
	}

	@include mediaQ(PC) {
		.ly_main { min-width: $pcMinInner;
			&_inner { padding: 60px 0 120px; }
		}
		&_title { font-size: 30px; padding-bottom: 20px; margin-bottom: 30px;
			&_area { width: 92px; height: 50px; font-size: 15px; margin-right: 22px; transform: translateY(-3px); }
		}
		&_intro { margin-bottom: 60px;
			&_inner { display: flex; justify-content: space-between; padding: 10px 40px 30px 10px; }
			&_photo { width: 516px;
				&_main { width: 100%; height: 340px; margin-bottom: 4px; }
				&_thumbList { padding: 0 27px ;
					&_item { width: 100px; padding-bottom: 80px; transition: opacity 0.3s; cursor: pointer;
						&:hover { opacity: 0.8; }
					}
				}
				&_caption { margin-bottom: 10px; font-size: 13px; letter-spacing: 0.06em; line-height: 1.5;}
			}
			&_txt { width: 594px; }
			&_table { margin-bottom: 20px;
				&_title, &_data { vertical-align: middle; }
				&_title { width: 135px; font-size: 18px; line-height: 1.333; vertical-align: top; padding: 19px 0; }
				&_data { font-size: 15px; line-height: 1.6; padding: 17px 0; }
			}
			&_rent { font-size: 32px; margin-right: 5px;
				&Blk { position: relative; top: -16px; }
			}
			&_unitPrice { margin-left: 5px; }
			&_cost { display: flex; flex-wrap: wrap; margin-top: -10px;
				&_unit { width: calc(100% / 4); letter-spacing: 0.06em;
					$unit: '.un_searchDetail_intro_cost_unit';
					&_title { display: flex; align-items: center; justify-content: center; height: 42px; font-size: 14px; }
					&_data { display: flex; align-items: center; justify-content: center; height: 38px; font-size: 13px; border-left: solid 1px #dfe4e8; border-bottom: solid 1px #dfe4e8; }
					&:not(:last-of-type) {
						#{$unit}_title { border-right: solid 1px #dfe4e8; }
					}
					&:first-of-type {
						#{$unit}_data { border-radius: 0 0 0 4px; }
					}
					&:last-of-type {
						#{$unit}_data { border-right: solid 1px #dfe4e8; border-radius: 0 0 4px; }
					}
				}
			}
			.un_searchDetail_btns {
				&_link { font-size: 16px; letter-spacing: 0.02em;
					$btnLink: ".un_searchDetail_btns_link";
					&#{$btnLink}__favorite {
						img { margin: 0 5px 0 -2px; }
					}
					&#{$btnLink}__mail {
						img { width: 22px; margin: 0 7px 0 -3px; }
					}
				}
			}
			&_description { padding: 20px 45px 25px 30px;
				&_txt { font-size: 16px; font-weight: 500; line-height: 1.875; }
			}
		}
		&_sectTitle { font-size: 28px; padding-bottom: 15px; margin: -5px 0 28px; }
		&_contents { display: flex; justify-content: space-between; flex-wrap: wrap;
			#{$root}_overview { width: 810px;
				#{$root}_sect { 
					$sect: ".un_searchDetail_sect";
					&_contTitle { font-size: 24px; margin-bottom: 15px; }
					&#{$sect}__basic { margin-bottom: 58px; }
					&_dataList { display: flex; justify-content: space-between; flex-wrap: wrap;
						$dataList: ".un_searchDetail_sect_dataList";
						&_group { width: 395px; 
							&:first-of-type, &:nth-of-type(2) {
								#{$dataList}_title { border-bottom: solid 1px #dfe4e8; border-radius: 4px 0 0; }
								#{$dataList}_data { border-top: solid 1px #dfe4e8; border-radius: 0 4px 0 0; }
							}
							&:nth-last-of-type(2), &:last-of-type {
								#{$dataList}_title { border-bottom: none; border-radius: 0 0 0 4px; }
								#{$dataList}_data { border-radius: 0 0 4px; }
							}
						}
						&_title { width: 132px; padding: 12px 40px 11px 20px; border-bottom: solid 1px #dfe4e8; }
						&_data { width: 261px; font-size: 13px; line-height: 1.62; padding: 9px 20px 7px; border-right: solid 1px #dfe4e8; border-bottom: solid 1px #dfe4e8; }
					}
					&#{$sect}__terms { margin-bottom: 55px; }
					&#{$sect}__map { margin-bottom: 75px;
						#{$sect}_mapData { width: 100%; height: 330px;
							iframe { width: 100%; height: 100%; }
						}
					}
				}
				&_No { font-size: 20px; letter-spacing: 0.035em; text-align: center; margin-bottom: 25px; }
				&_tel { width: 706px; padding: 0 24px 0 22px; margin: 0 auto;
					&_txt { font-size: 20px; letter-spacing: 0.02em; padding: 15px 0; }
					&_num { font-size: 30px; align-items: baseline; padding: 20px 0 25px;
						img { margin-right: 15px; transform: translate(4px, 7px); }
						&_time { font-size: 16px; margin-left: 15px; }
					}
				}
			}
			#{$root}_banners { width: 342px; }
		}	
		&_btns {
			$btns: ".un_searchDetail_btns";
			&#{$btns}__bottom { width: 706px; margin: 0 auto 40px;
				#{$btns}_link { height: 64px; font-size: 22px; letter-spacing: 0.02em;
					$btnsLink: '.un_searchDetail_btns_link';
					&#{$btnsLink}__favorite { width: 330px; 
						img { width: 28px; margin: 0 13px 0 -12px; }
					}
					&#{$btnsLink}__mail { width: 360px;
						img { width: 28px; margin: 0 22px 0 -3px; }
					}
				}
				
			}
			&_item {
				&:not(:last-of-type) { margin-right: 12px; }
			}
			&_link { height: 60px;
				$btnsLink: &;
				&#{$btnsLink}__favorite { width: 210px; }
				&#{$btnsLink}__mail { width: 238px; }
			}
		}
		&_banners {
			&_item {
				&:not(:last-of-type) { margin-bottom: 10px; }
			}
		}
		&_related { margin-top: 80px;
			.un_searchDetail_sectTitle { margin-bottom: 18px; }
			.slick-arrow { width: 31px; height: 61px;
				&:hover, &:focus { background:  #3cb446; }
				&.slick-prev, &.slick-next { transform: translate(0, -54%); z-index: 1000;
					&::before { content: ""; display: block; opacity: 1; width: 13px; height: 13px; }
				}
				&.slick-prev { left: -26px; border-radius: 4px 0 0 4px;
					&::before { border-top: solid 2px #fff; border-left: solid 2px #fff; transform: rotate(-45deg); margin-left: 10px; }
				}
				&.slick-next { right: -26px; border-radius: 0 4px 4px 0;
					&::before { border-top: solid 2px #fff; border-right: solid 2px #fff; transform: rotate(45deg); margin-left: 7px; }
				}
			}
			.slick-slide { margin: 0 5px; }
		}
	}

	@include mediaQ(SP) {
		.bl_breadClumb { display: none; }
		.ly_main { 
			&_inner { padding: 30px 0 125px; }
		}
		&_title { font-size: 21px; line-height: 1.47; padding-bottom: 14px; margin-bottom: 15px;
			&_area { width: 60px; height: 32px; font-size: 13px; margin-right: 10px; }
			&_txt { display: inline-block; width: calc(100% - 70px); }
		}
		&_intro { margin-bottom: 25px;
			&_photo { padding: 5px 5px 10px; border-bottom: solid 1px #dfe4e8;
				&_main { width: 100%; padding-bottom: 66.266%; margin-bottom: 5px; }
				&_thumbList { padding: 0 23px ;
					&_item { padding-bottom: 6%; }
					.slick-slide { margin: 0 1px; }
					.slick-arrow { 
						&.slick-prev, &.slick-next { width: 18px;}
					}
				}
				&_caption { margin-bottom: 12px;}
			}
			&_txt { padding: 5px 15px 20px; border-bottom: solid 1px #dfe4e8; }
			&_table { display: block; width: 100%; margin-bottom: 5px;
				$table: ".un_searchDetail_intro_table";
				tbody { display: block; }
				&_title { width: 107px; font-size: 15px; vertical-align: top; padding: 10px 0 12px; }
				&_data { width: calc( 100% - 107px); font-size: 13px; padding: 11px 0; }
				&_spNoBorder {
					#{$table}_title, #{$table}_data { border: none; }
				}
				&_spTr { display: flex; width: 100%; padding-bottom: 10px;
					&:not(:last-of-type) { border-bottom: solid 1px #dfe4e8; }
					td { display: block; }
					.un_searchDetail_intro_cost { margin-top: -10px;}
				}
			}
			&_rent { font-size: 24px; letter-spacing: 0.02em; margin-right: 5px;
				&Blk { position: relative; top: -8px; }
			}
			&_unitPrice { display: inline-block; margin-left: 2px; }
			&_cost {
				&_unit { display: flex; width: 100%;
					$unit: '.un_searchDetail_intro_cost_unit';
					&_title { width: 133px; padding: 11px 20px; }
					&:not(:last-of-type) {
						#{$unit}_title { border-bottom: solid 1px #dfe4e8; }
					}
					&_data { width: calc(100% - 133px); padding: 10px 20px; border-right: solid 1px #dfe4e8; border-bottom: solid 1px #dfe4e8; }
					&:first-of-type {
						#{$unit}_data { border-top: solid 1px #dfe4e8; border-radius: 0 4px 0 0; }
					}
					&:last-of-type {
						#{$unit}_data { border-radius: 0 0 4px; }
					}
				}
			}
			&_description { padding: 10px 20px 15px;
				&_txt { font-size: 14px; font-weight: 500; line-height: 1.72; }
			}
		}
		&_sectTitle { font-size: 21px; padding-bottom: 12px; margin-bottom: 20px; }
		&_contents {
			#{$root}_overview {
				#{$root}_sect {
					$sect: ".un_searchDetail_sect";
					&_contTitle { font-size: 18px; margin-bottom: 15px; }
					&#{$sect}__basic { margin-bottom: 27px; }
					&_dataList { overflow: hidden; border: solid 1px #dfe4e8; border-radius: 4px;
						&_group {
							&:not(:last-of-type) { border-bottom: solid 1px #dfe4e8; }
						}
						&_title { width: 132px; font-size: 14px; font-weight: 500; padding: 10px 20px; }
						&_data { width: calc(100% - 132px); font-size: 13px; padding: 11px 20px; }
					}
					&#{$sect}__terms { margin-bottom: 25px; }
					&#{$sect}__map { margin-bottom: 35px;
						#{$sect}_mapData { position: relative; width: 100%; height: 0; padding-bottom: 65.217%;
							iframe { position: absolute; top: 0; right: 0; bottom: 0; left: 0; width: 100%; height: 100%; }
						}
					}
				}
				&_No { font-size: 17px; font-weight: 500; letter-spacing: 0.02em; text-align: center; margin-bottom: 25px; }
				&_tel { width: 91.304%; padding: 0 20px; margin: 0 auto 40px;
					&_txt { font-size: 17px; padding: 12px 0 13px; }
					&_num { flex-wrap: wrap; font-size: 21px; line-height: 22px; padding: 16px 0 19px;
						img { width: 16px; margin: 0 5px 0 -10px; }
						&_time { display: block; width: 100%; font-size: 12px; line-height: 20px; text-align: center; margin-top: 2px; }
					}
				}
			}
			#{$root}_banners { margin-bottom: 45px;
				&_item {
					&:not(:last-of-type) { margin-bottom: 10px; }
				}
				&_link {
					img { width: 100%; }
				}
			}
		}
		.un_searchDetail_btns {
			$btns: '.un_searchDetail_btns';
			&#{$btns}__bottom { width: 91.304%; margin: 0 auto 27px; }
			&_item { width: 100%;
				&:not(:last-of-type) { margin-bottom: 7px; }
			}
			&_link { width: 100%; height: 50px; font-size: 18px; letter-spacing: 0.04em;
				$btnLink: ".un_searchDetail_btns_link";
				&#{$btnLink}__favorite {
					img { width: 26px; margin-right: 12px; }
				}
				&#{$btnLink}__mail {
					img { width: 27px; margin-right: 13px; }
				}
			}
		}
		&_related {
			&:not(:last-of-type) { margin-bottom: 80px; }
			.un_searchDetail_sectTitle { margin-bottom: 20px; }
			.slick-arrow { top: calc(100% + 10px); width: 82px; height: 30px; transform: translate(0);
				&:focus { background:  #3cb446; }
				&.slick-prev, &.slick-next {
					&::before { content: ""; display: block; opacity: 1; position: absolute; top: 50%; width: 10px; height: 10px;}
					&:hover { color: #fff; background: #3cb446; }
				}
				&.slick-prev { left: 3px;
					&::before { left: 20px; border-top: solid 2px #fff; border-left: solid 2px #fff; transform: translateY(-50%) rotate(-45deg); }
					&::after { content: "前へ"; position: absolute; top: 50%; left: 36px; color: #fff; font-size: 12px; font-weight: 500; transform: translateY(-50%); }
				}
				&.slick-next { right: 3px;
					&::before { right: 20px; border-top: solid 2px #fff; border-right: solid 2px #fff; transform: translateY(-50%) rotate(45deg); }
					&::after { content: "次へ"; position: absolute; top: 50%; right: 36px; color: #fff; font-size: 12px; font-weight: 500; transform: translateY(-50%); }
				}
			}
			.slick-track { display: flex; }
			.slick-slide { float: none; height: auto; margin: 0 3px; }
		}
	}
}

