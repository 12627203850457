@charset "utf-8";

/* =========================================================
　◆Foot
========================================================= */
.ly_foot { background: #fff; border-top: solid 1px #e4eaf0;
	&_inner { margin: 0 auto; }
	&_description {
		&_txt { letter-spacing: 0.06em; }
	}
	&_search { background: #f0f6fa;
		&_title { font-size: 18px; }
		&_list { display: flex; }
		&_link { display: flex; align-items: center; justify-content: center; color: #fff; border-radius: 3px;
			$link: &;
			&#{$link}__hokkaido { background: $hokkaidoBg; }
			&#{$link}__tohoku { background: $tohokuBg; }
			&#{$link}__kanto { background: $kantoBg; }
		}
	}
	&_info { background: #8296aa;
		&_inner { display: flex; align-items: center; justify-content: space-between; }
		&_logo {
			&_link { display: flex; align-items: center; }
		}
		&_nav { display: flex; align-items: center;
			&_link { color: #fff; }
		}
	}
	.bl_copy { display: block; color: #fff;letter-spacing: 0.06em; text-align: center; }

	@include mediaQ(PC) {
		& { min-width: $pcMinInner;
			&_inner { width: $pcInner; }
			&_description { padding: 20px 0;
				&_txt { font-size: 14px; }
			}
			&_search { padding: 10px 0;
				&_inner { display: flex; align-items: center; justify-content: space-between; }
				&_title { margin-right: 26px; }
				&_item:not(:last-of-type) { margin-right: 4px; border-radius: 3px; }
				&_link {  width: 72px; height: 50px; }
			}
			&_info { padding: 48px 0;
				&_inner { margin-bottom: 50px;}
				&_logo {
					&_link { 
						&_mark { margin-right: 10px; }
					}
				}
				&_nav {
					&_item:not(:last-of-type) { margin-right: 36px; }
					&_link { font-size: 15px; letter-spacing: 0.06em; }
				}
			}
			.bl_copy { font-size: 11px; }
		}
	}

	@include mediaQ(SP) {
		& {
			&_inner { width: $spInner; }
			&_description { padding: 14px 0;
				&_txt { font-size: 12px; line-height: 1.5; text-align: center; }
			}
			&_search { padding: 15px 0 18px; border-top: solid 1px #e4eaf0;
				&_title { text-align: center; margin-bottom: 10px; }
				&_list { flex-wrap: wrap; justify-content: space-between;
					&::before, &::after { content: ""; display: block; width: 24.347%; }
					&::before { order: 1; }
				}
				&_item { width: 24.347%; height: 35px;
					&:not(:first-of-type) { border-radius: 2px; }
					&:nth-of-type(n+5) { margin-top: 3px; }
				}
				&_link { display: flex; align-items: center; height: 100%; }
			}
			&_info { padding: 0 0 40px;
				& .ly_foot_inner { width: 100%; }
				&_inner { flex-direction: column-reverse; }
				&_logo { margin-bottom: 15px;
					&_link { 
						&_mark { max-width: 33px; margin-right: 8px; }
						&_txt { max-width: 154px; }
					}
				}
				&_nav { flex-wrap: wrap; width: 100%; margin-bottom: 20px;
					&::after { content: ""; display: block; width: 50%; height: 41px; border-bottom: solid 1px #bec8d2; }
					&_item { display: flex; align-items: center; justify-content: center; width: 50%; height: 41px; border-bottom: solid 1px #bec8d2;
						&:nth-of-type(odd) { border-right: solid 1px #bec8d2; }
					}
					&_link { display: flex; align-items: center; justify-content: center; width: 100%; height: 100%; font-size: 12px; }
				}
			}
			.bl_copy { font-size: 10px; }
		}
	}
}

