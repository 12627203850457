@charset "utf-8";

/* =========================================================
　◆Link Style
========================================================= */
a { transition: opacity $animationTime;
	&:link, &:visited, &:active { text-decoration: none; }
	&:hover { text-decoration: none; opacity: 0.8; }
}

/* =========================================================
　◆Basic
========================================================= */
* { box-sizing: border-box;
	&:before, &:after { box-sizing: border-box; }
}
html { -webkit-text-size-adjust: none; }
body { font: 13px / 1.4 'Noto Sans JP', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; background: #fff;	-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
img { max-width: 100%; height: auto; width: auto; vertical-align: top; display: block; }
figure { border: 0; outline: 0; font-size: 100%; vertical-align: baseline; background: transparent; }

/* clear */
.clear  { clear: both !important; }
/* clearfix */
.cFix:after { @include cFix; }

@include mediaQ(PC) {
	/* pcBlock,pcInline */
	.pcBlock { display: block !important; }
	.pcInline { display: inline-block !important; }
	.pcFlex { display: flex !important; }
	.spBlock, .spInline, .spFlex { display: none !important; }
}
@include mediaQ(SP) {
	body{
		&.is_menuOpen { overflow: hidden; }
	}
	/* spBlock,spInline */
	.spBlock { display: block !important;}
	.spInline { display: inline-block !important;}
	.spFlex { display: flex !important; }
	.pcBlock, .pcInline, .pcFlex { display: none !important;}
}
